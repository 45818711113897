/* eslint-disable max-lines */
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import * as Progress from 'react-native-progress';
import * as ImagePicker from 'expo-image-picker';
import { Platform, Switch, ScrollView } from 'react-native';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { ptBR } from 'date-fns/locale';
import AwesomeAlert from 'react-native-awesome-alerts';
import { Header } from '../../../components/Header';
import { defaultTheme as theme } from '../../../styles/theme';
import { InputHookForm } from '../../../components/InputHookform';
import { editEvent, listCategories } from '../../../services/events';
import { uploadFile } from '../../../services/toolsApi';
import { InputSelect } from '../../../components/InputSelect';
import { Errors } from '../../../components/Errors';
import { Heading } from '../../../components/Heading';
import { Button } from '../../../components/Button';
import mobileInvitation from '../../../assets/images/event/mobileInvitation/invitationEmpty.png';
import webInvite from '../../../assets/images/event/webInvite.png';
import { Loading } from '../../../components/Loading';
import { LoadingPhoto } from '../../../components/LoadingPhoto';

import * as S from './styles';

const isWeb = Platform.OS === 'web';

interface AddEventScreenProps {
    navigation: NavigationProp<{}>,
    route: RouteProp<any, any>,
}

export function EventEditScreen({ navigation, route }: AddEventScreenProps) {
    const event = route?.params?.event;
    const dateEvent = new Date(event.date);
    const date = format(dateEvent.setDate(dateEvent.getDate() + 1), 'dd/MM/yyyy', { locale: ptBR });
    const [idCategory, setIdCategory] = useState(String(event.idCategory));
    const [frontCoverImage, setFrontCoverImage] = useState(event.front_cover);
    const [frontCoverImageUrl, setFrontCoverImageUrl] = useState(event.front_cover);
    const [frontCoverImageLoading, setFrontCoverImageLoading] = useState(false);
    const [invitationImage, setInvitationImage] = useState(event.invitation);
    const [invitationImageUrl, setInvitationImageUrl] = useState(event.invitation);
    const [invitationImageLoading, setInvitationImageLoading] = useState(false);
    const [progress, setProgress] = useState(0.5);
    const [screen, setScreen] = useState(1);
    const [isEnabled, setIsEnabled] = useState(event.is_vaccination);
    const [isPhoto, setIsPhoto] = useState(event.is_photo_guests);
    const [isGuestGroup, setIsGuestGroup] = useState(event.is_guest_group);
    const [isEmail, setIsEmail] = useState(event.is_email || false);
    const [isRg, setIsRg] = useState(event.is_rg || false);
    const [isCpf, setIsCpf] = useState(event.is_cpf || false);
    const [isAddress, setIsAddress] = useState(event.is_address || false);
    const [isNumberShoe, setIsNumberShoe] = useState(event.is_number_shoe || false);
    const [isProfession, setIsProfession] = useState(event.is_profession || false);
    const [isOffice, setIsOffice] = useState(event.is_office || false);
    const [isBusiness, setIsBusiness] = useState(event.is_business || false);
    const [buttonTitle, setButtonTitle] = useState('CONTINUAR');
    const { control, handleSubmit, formState: { errors }, setError } = useForm();
    const [openModalNotification, setOpenModalNotification] = useState(false);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingEditEvent, setLoadingEditEvent] = useState(false);
    const toggleSwitch = () => setIsEnabled((previousState) => !previousState);
    const toggleSwitchPhoto = () => setIsPhoto((previousState) => !previousState);
    const toggleSwitchGroup = () => setIsGuestGroup((previousState) => !previousState);
    const toggleSwitchEmail = () => setIsEmail((previousState) => !previousState);
    const toggleSwitchRg = () => setIsRg((previousState) => !previousState);
    const toggleSwitchCpf = () => setIsCpf((previousState) => !previousState);
    const toggleSwitchAddress = () => setIsAddress((previousState) => !previousState);
    const toggleSwitchNumberShoe = () => setIsNumberShoe((previousState) => !previousState);
    const toggleSwitchProfession = () => setIsProfession((previousState) => !previousState);
    const toggleSwitchOffice = () => setIsOffice((previousState) => !previousState);
    const toggleSwitchBusiness = () => setIsBusiness((previousState) => !previousState);

    const thumbColorVaccination = isEnabled ? '#7630BE' : '#f4f3f4';
    const thumbColorPhoto = isPhoto ? '#7630BE' : '#f4f3f4';
    const thumbColorGroup = isGuestGroup ? '#7630BE' : '#f4f3f4';
    const thumbColorEmail = isEmail ? '#7630BE' : '#f4f3f4';
    const thumbColorRg = isRg ? '#7630BE' : '#f4f3f4';
    const thumbColorCpf = isCpf ? '#7630BE' : '#f4f3f4';
    const thumbColorAddress = isAddress ? '#7630BE' : '#f4f3f4';
    const thumbColorNumberShoe = isNumberShoe ? '#7630BE' : '#f4f3f4';
    const thumbColorProfession = isProfession ? '#7630BE' : '#f4f3f4';
    const thumbColorOffice = isOffice ? '#7630BE' : '#f4f3f4';
    const thumbColorBusiness = isBusiness ? '#7630BE' : '#f4f3f4';
    const switchConfig = {
        trackColor: { false: '#767577', true: 'rgba(118, 48, 190, 0.5)' },
        styles: {
            transform: [
                { scaleX: 0.8 },
                { scaleY: 0.8 },
            ],
        },
    };

    const pickImageFrontCover = async () => {
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
        });

        if (!result.cancelled) {
            setFrontCoverImage(result.uri);
            setFrontCoverImageLoading(true);
            const file = {
                name: result.uri.split('/').pop(),
                type: 'image/jpeg',
                uri: result.uri,
            };
            const response = await uploadFile(file);
            if (response.status === 200) {
                setFrontCoverImageLoading(false);
                setFrontCoverImageUrl(response.result);
            }
        }
    };

    const pickImageInvitation = async () => {
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
        });
        if (!result.cancelled) {
            setInvitationImageLoading(true);
            setInvitationImage(result.uri);
            const file = {
                name: result.uri.split('/').pop(),
                type: 'image/jpeg',
                uri: result.uri,
            };
            const response = await uploadFile(file);
            if (response.status === 200) {
                setInvitationImageLoading(false);
                setInvitationImageUrl(response.result);
            }
        }
    };

    const formattedDate = (value: string): string => {
        const [day, month, year] = value.split('/');
        return `${year}-${month}-${day}`;
    };

    const handleContinue = (data) => {
        const dateFormated = formattedDate(data.date);
        const isBigger = new Date(`${dateFormated}`).setHours(24, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0);

        if (!isBigger) {
            setError('date', { type: 'validate', message: 'Data inválida' });
            return null;
        }
        if (Number(idCategory) === 0) {
            setError('idCategory', { type: 'validate', message: 'Selecione uma categoria' });
            return null;
        }

        if (screen <= 1) {
            setScreen(2);
            setProgress(1);
            setButtonTitle('FINALIZAR');
        } else {
            return null;
        }

        return null;
    };

    const handleEditEvent = async (data) => {
        setLoadingEditEvent(true);
        const eventData = {
            ...data,
            date: formattedDate(data.date),
            frontCover: frontCoverImageUrl,
            invitation: invitationImageUrl,
            idCategory: Number(idCategory),
            isVaccination: isEnabled,
            isPhotoGuests: isPhoto,
            isGuestGroup,
            isEmail,
            isRg,
            isCpf,
            isAddress,
            isNumberShoe,
            isProfession,
            isOffice,
            isBusiness,
        };
        editEvent(eventData, Number(event.id)).then((response) => {
            if (response.status === 200) {
                setOpenModalNotification(true);
                setLoadingEditEvent(false);
            }
        });
    };

    const handleNavigationHome = () => {
        navigation.reset({
            index: 0,
            routes: [{ name: 'Home' } as never],
        });
    };

    const goBack = () => {
        if (screen > 1) {
            setScreen(1);
            setProgress(0.5);
            setButtonTitle('CONTINUAR');
        } else {
            navigation.reset({
                index: 0,
                routes: [{ name: 'EventDetailsScreen', params: { event } }],
            });
        }
    };

    function ErrorsComponent({ message }) {
        return <Errors message={message} />;
    }


    async function getCategories() {
        await listCategories().then((response) => {
            const categoriesFormated = response.result.list.map((e) => {
                return {
                    value: e.id,
                    label: e.name,
                };
            });
            setCategories(categoriesFormated);
            setLoading(false);
        });

    }


    useEffect(() => {
        getCategories();
        setScreen(1);
        setProgress(0.5);
        setButtonTitle('CONTINUAR');
    }, []);

    useEffect(() => {
        navigation.setOptions({
            title: `Editar evento | ${event.name}`,
        });
    }, [navigation, route]);

    return (
        <>
            {loading ? <Loading /> : (
                <S.Safe>
                    <S.Container>
                        <Header handleGoback={goBack} title='Editar Evento' navigation={navigation} />
                        <S.ProgressContainer>
                            <Progress.Bar progress={progress} color={theme.colors.purpleDark} height={6} borderRadius={10} width={null} />
                        </S.ProgressContainer>
                        <S.Content>
                            {screen === 1 ? (
                                <>
                                    <Heading color='gray' size='intermediate'>Informações do evento</Heading>
                                    <ScrollView>
                                        <S.Form>
                                            <InputHookForm
                                                errors={errors.name}
                                                name='name'
                                                control={control}
                                                label='Nome'
                                                type='text'
                                                placeholder='Digite o nome do evento'
                                                valueInicial={event.name}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name='name'
                                                render={ErrorsComponent}
                                            />
                                            <S.InputsInLine>
                                                <S.ViewInput>
                                                    <InputHookForm
                                                        errors={errors.date}
                                                        name='date'
                                                        control={control}
                                                        label='Data'
                                                        type='date'
                                                        placeholder='__/__/__'
                                                        valueInicial={date}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name='date'
                                                        render={ErrorsComponent}
                                                    />
                                                </S.ViewInput>
                                                <S.ViewInput>

                                                    <InputHookForm
                                                        errors={errors.time}
                                                        name='time'
                                                        control={control}
                                                        label='Horário'
                                                        type='hours'
                                                        placeholder='Hora:min'
                                                        valueInicial={event.time}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name='time'
                                                        render={ErrorsComponent}
                                                    />
                                                </S.ViewInput>
                                            </S.InputsInLine>
                                            {categories.length > 0 && <InputSelect list={categories} setProps={setIdCategory} initialValue={idCategory} label='Categoria' />}
                                            <ErrorMessage
                                                errors={errors}
                                                name='idCategory'
                                                render={ErrorsComponent}
                                            />

                                            <InputHookForm
                                                errors={errors.address}
                                                name='address'
                                                control={control}
                                                label='Endereço'
                                                type='text'
                                                placeholder='Digite o endereço do evento'
                                                valueInicial={event.address}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name='address'
                                                render={ErrorsComponent}

                                            />

                                            <InputHookForm
                                                errors={errors.color}
                                                name='color'
                                                control={control}
                                                label='Cor do background'
                                                type='hex'
                                                placeholder='Hex'
                                                valueInicial={event.color}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name='color'
                                                render={ErrorsComponent}
                                            />
                                            <InputHookForm
                                                errors={errors.nameColor}
                                                name='nameColor'
                                                control={control}
                                                label='Cor do título'
                                                type='hex'
                                                placeholder='Hex'
                                                valueInicial={event.name_color}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name='nameColor'
                                                render={ErrorsComponent}
                                            />
                                            <InputHookForm
                                                errors={errors.messageWhatsapp}
                                                name='messageWhatsapp'
                                                control={control}
                                                label='Mensagem Whatsapp'
                                                type='descriptions'
                                                valueInicial={event.message_whatsapp}
                                                align='flex-start'
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name='messageWhatsapp'
                                                render={ErrorsComponent}
                                            />
                                            <InputHookForm
                                                errors={errors.description}
                                                name='description'
                                                control={control}
                                                label='Descrição'
                                                type='descriptions'
                                                valueInicial={event.description}
                                                align='flex-start'
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name='description'
                                                render={ErrorsComponent}
                                            />
                                            <InputHookForm
                                                errors={errors.zapiInstance}
                                                name='zapiInstance'
                                                control={control}
                                                label='Z-API Instância'
                                                type='text'
                                                align='flex-start'
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name='zapiInstance'
                                                render={ErrorsComponent}
                                            />
                                            <InputHookForm
                                                errors={errors.zapiToken}
                                                name='zapiToken'
                                                control={control}
                                                label='Z-API Token'
                                                type='text'
                                                align='flex-start'
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name='zapiToken'
                                                render={ErrorsComponent}
                                            />
                                            <S.VacinityContainer mt={16}>
                                                <Heading color='gray' size='small'>
                                                    Comprovante de vacinação
                                                </Heading>
                                                <Switch
                                                    trackColor={switchConfig.trackColor}
                                                    thumbColor={thumbColorVaccination}
                                                    ios_backgroundColor='#B2BEC3'
                                                    onValueChange={toggleSwitch}
                                                    value={isEnabled}
                                                    style={switchConfig.styles}
                                                />
                                            </S.VacinityContainer>
                                            <S.VacinityContainer mt={5}>
                                                <Heading color='gray' size='small'>
                                                    Foto de perfil
                                                </Heading>
                                                <Switch
                                                    trackColor={switchConfig.trackColor}
                                                    thumbColor={thumbColorPhoto}
                                                    ios_backgroundColor='#B2BEC3'
                                                    onValueChange={toggleSwitchPhoto}
                                                    value={isPhoto}
                                                    style={switchConfig.styles}
                                                />
                                            </S.VacinityContainer>
                                            <S.VacinityContainer mt={5}>
                                                <Heading color='gray' size='small'>
                                                    Permitido acompanhante(s)
                                                </Heading>
                                                <Switch
                                                    trackColor={switchConfig.trackColor}
                                                    thumbColor={thumbColorGroup}
                                                    ios_backgroundColor='#B2BEC3'
                                                    onValueChange={toggleSwitchGroup}
                                                    value={isGuestGroup}
                                                    style={switchConfig.styles}
                                                />
                                            </S.VacinityContainer>
                                            <S.VacinityContainer mt={5}>
                                                <Heading color='gray' size='small'>
                                                    Permitido email
                                                </Heading>
                                                <Switch
                                                    trackColor={switchConfig.trackColor}
                                                    thumbColor={thumbColorEmail}
                                                    ios_backgroundColor='#B2BEC3'
                                                    onValueChange={toggleSwitchEmail}
                                                    value={isEmail}
                                                    style={switchConfig.styles}
                                                />
                                            </S.VacinityContainer>
                                            <S.VacinityContainer mt={5}>
                                                <Heading color='gray' size='small'>
                                                    Permitido RG
                                                </Heading>
                                                <Switch
                                                    trackColor={switchConfig.trackColor}
                                                    thumbColor={thumbColorRg}
                                                    ios_backgroundColor='#B2BEC3'
                                                    onValueChange={toggleSwitchRg}
                                                    value={isRg}
                                                    style={switchConfig.styles}
                                                />
                                            </S.VacinityContainer>
                                            <S.VacinityContainer mt={5}>
                                                <Heading color='gray' size='small'>
                                                    Permitido CPF
                                                </Heading>
                                                <Switch
                                                    trackColor={switchConfig.trackColor}
                                                    thumbColor={thumbColorCpf}
                                                    ios_backgroundColor='#B2BEC3'
                                                    onValueChange={toggleSwitchCpf}
                                                    value={isCpf}
                                                    style={switchConfig.styles}
                                                />
                                            </S.VacinityContainer>
                                            <S.VacinityContainer mt={5}>
                                                <Heading color='gray' size='small'>
                                                    Permitido endereço
                                                </Heading>
                                                <Switch
                                                    trackColor={switchConfig.trackColor}
                                                    thumbColor={thumbColorAddress}
                                                    ios_backgroundColor='#B2BEC3'
                                                    onValueChange={toggleSwitchAddress}
                                                    value={isAddress}
                                                    style={switchConfig.styles}
                                                />
                                            </S.VacinityContainer>
                                            <S.VacinityContainer mt={5}>
                                                <Heading color='gray' size='small'>
                                                    Permitido número da sandália
                                                </Heading>
                                                <Switch
                                                    trackColor={switchConfig.trackColor}
                                                    thumbColor={thumbColorNumberShoe}
                                                    ios_backgroundColor='#B2BEC3'
                                                    onValueChange={toggleSwitchNumberShoe}
                                                    value={isNumberShoe}
                                                    style={switchConfig.styles}
                                                />
                                            </S.VacinityContainer>
                                            <S.VacinityContainer mt={5}>
                                                <Heading color='gray' size='small'>
                                                    Permitido profissão
                                                </Heading>
                                                <Switch
                                                    trackColor={switchConfig.trackColor}
                                                    thumbColor={thumbColorProfession}
                                                    ios_backgroundColor='#B2BEC3'
                                                    onValueChange={toggleSwitchProfession}
                                                    value={isProfession}
                                                    style={switchConfig.styles}
                                                />
                                            </S.VacinityContainer>
                                            <S.VacinityContainer mt={5}>
                                                <Heading color='gray' size='small'>
                                                    Permitido cargo
                                                </Heading>
                                                <Switch
                                                    trackColor={switchConfig.trackColor}
                                                    thumbColor={thumbColorOffice}
                                                    ios_backgroundColor='#B2BEC3'
                                                    onValueChange={toggleSwitchOffice}
                                                    value={isOffice}
                                                    style={switchConfig.styles}
                                                />
                                            </S.VacinityContainer>
                                            <S.VacinityContainer mt={5}>
                                                <Heading color='gray' size='small'>
                                                    Permitido nome da empresa
                                                </Heading>
                                                <Switch
                                                    trackColor={switchConfig.trackColor}
                                                    thumbColor={thumbColorBusiness}
                                                    ios_backgroundColor='#B2BEC3'
                                                    onValueChange={toggleSwitchBusiness}
                                                    value={isBusiness}
                                                    style={switchConfig.styles}
                                                />
                                            </S.VacinityContainer>
                                            <S.ContainerButton>
                                                <Button title={buttonTitle} onClick={handleSubmit(handleContinue)} />
                                            </S.ContainerButton>
                                        </S.Form>
                                    </ScrollView>
                                </>
                            )
                                : (
                                    <>
                                        <ScrollView>
                                            <Heading color='gray' size='intermediate'>Capa</Heading>
                                            <S.ButtonImageEvent height={151} onPress={pickImageFrontCover}>
                                                {frontCoverImage ? (
                                                    <S.ImageButton source={{ uri: frontCoverImage }} resizeMode='cover' />
                                                ) : (
                                                    <S.ImageButton source={isWeb ? webInvite : mobileInvitation} resizeMode='cover' />
                                                )}
                                                {frontCoverImageLoading && <LoadingPhoto />}
                                                <S.IconCan name='camera' />
                                            </S.ButtonImageEvent>
                                            <S.ButtonLabel>Insira uma foto</S.ButtonLabel>

                                            <Heading color='gray' size='intermediate'>Convite</Heading>
                                            <S.ButtonImageEvent
                                                height={isWeb ? 400 : 450}
                                                width={250}
                                                onPress={pickImageInvitation}
                                            >
                                                {invitationImage ? (
                                                    <S.ImageButton source={{ uri: invitationImage }} resizeMode='stretch' />

                                                ) : (
                                                    <S.ImageButton source={isWeb ? webInvite : mobileInvitation} resizeMode='cover' />
                                                )}
                                                {invitationImageLoading && <LoadingPhoto />}
                                                <S.IconCan name='camera' />
                                            </S.ButtonImageEvent>
                                            <S.ButtonLabel>Insira uma foto</S.ButtonLabel>
                                            <InputHookForm
                                                errors={errors.widthInvitation}
                                                name='widthInvitation'
                                                control={control}
                                                label='Largura do convite'
                                                type='number'
                                                align='flex-start'
                                                valueInicial={event.width_invitation}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name='widthInvitation'
                                                render={ErrorsComponent}
                                            />
                                            <InputHookForm
                                                errors={errors.heightInvitation}
                                                name='heightInvitation'
                                                control={control}
                                                label='Altura do convite'
                                                type='number'
                                                align='flex-start'
                                                valueInicial={event.height_invitation}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name='heightInvitation'
                                                render={ErrorsComponent}
                                            />
                                            <InputHookForm
                                                errors={errors.widthQrcode}
                                                name='widthQrcode'
                                                control={control}
                                                label='Largura do QRCODE'
                                                type='number'
                                                align='flex-start'
                                                valueInicial={event.width_qrcode}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name='widthQrcode'
                                                render={ErrorsComponent}
                                            />
                                            <InputHookForm
                                                errors={errors.heightQrcode}
                                                name='heightQrcode'
                                                control={control}
                                                label='Altura do QRCODE'
                                                type='number'
                                                align='flex-start'
                                                valueInicial={event.height_qrcode}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name='heightQrcode'
                                                render={ErrorsComponent}
                                            />
                                            <InputHookForm
                                                errors={errors.positionYInvitation}
                                                name='positionYInvitation'
                                                control={control}
                                                label='Posição vertical do QRCODE'
                                                type='number'
                                                align='flex-start'
                                                valueInicial={event.y_position_qrcode}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name='positionYInvitation'
                                                render={ErrorsComponent}
                                            />
                                            <InputHookForm
                                                errors={errors.positionXInvitation}
                                                name='positionXInvitation'
                                                control={control}
                                                label='Posição horizontal do QRCODE'
                                                type='number'
                                                align='flex-start'
                                                valueInicial={event.x_position_qrcode}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name='positionXInvitation'
                                                render={ErrorsComponent}
                                            />
                                            <S.ContainerButton isFinal>
                                                <Button loading={loadingEditEvent} title={buttonTitle} onClick={handleSubmit(handleEditEvent)} />
                                            </S.ContainerButton>
                                        </ScrollView>
                                    </>
                                )}
                        </S.Content>

                    </S.Container>
                    <AwesomeAlert
                        show={openModalNotification}
                        title='Evento editado!'
                        message='As alterações foram realizadas.'
                        closeOnTouchOutside={false}
                        closeOnHardwareBackPress={false}
                        showConfirmButton
                        confirmText='OK'
                        confirmButtonColor='#98C828'
                        onConfirmPressed={handleNavigationHome}
                    />
                </S.Safe>
            )}
        </>
    );
}
