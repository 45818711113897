import styled from 'styled-components/native';

interface ContainerProps {
    position: number;
    secondInfo?: boolean
    thirdInfo?: boolean,
}

interface ThemeColorProps{
    color: string
}

export const Container = styled.View<ContainerProps>`
    position:absolute;
    flex-direction: row;
    align-items: flex-start;
    ${(props) => props.position === 1 && props.secondInfo && 'top:35px; right:-30px'}
    ${(props) => props.position === 1 && !props.secondInfo && 'top:0px; right:0px'}
    ${(props) => props.position === 2 && props.secondInfo && 'bottom:0px; left:0px'}
    ${(props) => props.position === 2 && !props.secondInfo && 'bottom:35px; right:-20px'}
    ${(props) => props.position === 3 && props.secondInfo && 'bottom:35px; left:-20px'}
    ${(props) => props.position === 3 && !props.secondInfo && 'bottom:0px; right:0px'}
    ${(props) => props.position === 4 && props.secondInfo && 'top:35px; left:-20px'}
    ${(props) => props.position === 4 && !props.secondInfo && 'top:0px; right:0px'}
    ${(props) => props.position === 1 && props.thirdInfo && 'top:70px; right:-40px'}
    ${(props) => props.position === 2 && props.thirdInfo && 'bottom:20px; left:0px'}
    ${(props) => props.position === 3 && props.thirdInfo && 'bottom:70px; left:-20px'}
    ${(props) => props.position === 4 && props.thirdInfo && 'top:70px; left:-20px'}
`;

export const Title = styled.Text`
    font-size: 14px;
    font-weight: 700;
    color: #000;
`;

export const Label = styled.Text`
    font-size: 12px;
    font-weight: 400;
    color: #6e6e6e;
`;

export const ContainerInfos = styled.View`
    align-items: flex-start;
`;

export const Pointer = styled.View<ThemeColorProps>`
    height: 10px;
    width: 10px;
    background-color: ${({ color }) => color};
    border-radius: 5px;
    margin-right: 5px;
    margin-top: 4px;
`;

export const LabelButton = styled.TouchableOpacity``;
