import { RFValue } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';

import { AntDesign } from '@expo/vector-icons';
import { Platform, TouchableOpacityProps, Dimensions } from 'react-native';
import { defaultTheme as theme } from '../../../../../styles/theme';

const windowWidth = Dimensions.get('window').width;
const isWeb = Platform.OS === 'web';

interface ContainerProps extends TouchableOpacityProps {
    isOpen?: boolean
}


export const Shadow = Platform.OS === 'android' && {
    shadowColor: '#000',
    shadowOffset: {
        width: 100,
        height: 8,
    },
    shadowOpacity: 0.1,
    shadowRadius: 50,

    elevation: 10,
};

export const Container = styled.TouchableOpacity<ContainerProps>`
    /* width: ${isWeb && windowWidth > 650 ? '80%' : '100%'}; */
    flex: 1;
    background-color: #fff;
    margin-bottom: ${isWeb && windowWidth > 650 ? '1rem' : `${RFValue(12)}px`};
    padding: 10px;
    box-shadow: 5px 5px 6px #00000029;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 5px;
    align-content: flex-end;
    /* background-color: blue; */
    margin-left: 12px;
    margin-right: 24px;

    ${(props) => !props.isOpen && 'height: 100px'};
`;
export const Box = styled.View`
    flex-direction: row;
`;
export const Image = styled.Image`
    height: ${isWeb && windowWidth > 650 ? '2.875rem' : `${RFValue(46)}px`};
    width: ${isWeb && windowWidth > 650 ? '2.875rem' : `${RFValue(46)}px`};
    border-radius: 4px;
    margin-right: 12px;
`;
export const ImagePresence = styled.Image`
    width: 16px;
    height: 16px;
    margin-left: 12px;
`;
export const Content = styled.View`
    justify-content: space-between;
    max-width: 70%;
`;

export const Name = styled.Text`
    font-size: ${isWeb && windowWidth > 650 ? '0.75rem' : `${RFValue(12)}px`};
    font-family: ${theme.font.bold};
    color: ${theme.colors.gray};
    margin-bottom: 3px;
`;
export const Email = styled.Text`
    font-size: ${isWeb && windowWidth > 650 ? '0.625rem' : `${RFValue(10)}px`};
    font-family: ${theme.font.normal};
    color: ${theme.colors.gray};
`;
export const Phone = styled.Text`
    font-size: ${isWeb && windowWidth > 650 ? '0.625rem' : `${RFValue(10)}px`};
    font-family: ${theme.font.normal};
    color: ${theme.colors.gray};
`;

export const ButtonStar = styled.TouchableOpacity``;

export const Icon = styled(AntDesign)`
    font-size: ${RFValue(14)}px;
    color: ${({ star }: any) => (star ? theme.colors.star : theme.colors.gray)};
`;


export const Options = styled.View`
    margin-top: 12px;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
`;

export const ShowMore = styled.TouchableOpacity`
    margin-top: 11px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 7px;
    padding-bottom: 7px;
`;
export const ShowMoreText = styled.Text`
    font-family: ${theme.font.regular};
    font-size: 12px;
    color: #B9C3C8;
`;
export const ShowMoreIcon = styled(AntDesign)`
    font-size: 13px;
    margin-left: 10px;
    color: ${theme.colors.grayLight};
`;


export const IconStatusContainer = styled.View`
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
    /* background-color: orange; */
    /* align-items: flex-end; */
`;

export const QrCodeIcon = styled.Image`
    width: 16px;
    height: 16px;
`;

export const MainName = styled.Text`
    font-size: ${isWeb && windowWidth > 650 ? '0.625rem' : `${RFValue(10)}px`};
    font-family: ${theme.font.normal};
    color: ${theme.colors.gray};
`;
