import { defaultTheme as theme } from '../../../styles/theme';

export interface DataProps {
    guests: {
        defaulters: number;
        defaultersTotal: number;
        gift: number;
        giftTotal: number;
        total: number;
    };
    scans: {
        correct: number;
        correctTotal: number;
        incorrect: number;
        incorrectTotal: number;
        refused: number;
        refusedTotal: number;
        total: number;
    };
}

interface SvgProps {
    fill: string;
}

export interface PieProps {
    value: number;
    svg: SvgProps;
}

const chartColor = {
    colorEmpty: theme.colors.chart.empty,
    guests: [
        theme.colors.chart.guests.defaulters,
        theme.colors.chart.guests.gift,
        theme.colors.chart.empty,
    ],
    scans: [
        theme.colors.chart.scans.correct,
        theme.colors.chart.scans.incorrect,
        theme.colors.chart.scans.refused,
        theme.colors.chart.empty,
    ],
};

export function graphicsGeneration(data: any, type: 'guests' | 'scans') {
    const fillEmpty = { value: 1, svg: { fill: chartColor.colorEmpty } };
    const infosEmpty: PieProps[] = [fillEmpty];
    const slice = type === 'guests' ? 2 : 3;

    const newArray: number[] = Object.values(data[type]);
    const pieChartInfo = newArray.map((el, index) => {
        const colors = chartColor[type];
        const infos = { value: el, svg: { fill: colors[index] } };
        return infos;
    });

    const validation = {
        guests: data.guests.defaulters || data.guests.gift,
        scans: data.scans.correct || data.scans.incorrect || data.scans.refused,
    };

    if (validation[type]) {
        return pieChartInfo.slice(0, slice);
    }

    return infosEmpty;
}

export const calculePercentage = (total: number, value: number) => {
    const valueTotal = total || 0;
    const valueCalc = value || 0;
    const percentage = (valueCalc / valueTotal) * 100;
    return Number(percentage.toFixed(2));
};
