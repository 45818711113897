import React from 'react';
import { Dimensions } from 'react-native';

import { PieProps, DataProps } from '../../utils';
import { Heading } from '../../../../../components/Heading';
import { ChartInfos } from '../ChartInfos';
import { defaultTheme as theme } from '../../../../../styles/theme';
import * as S from './styles';

interface ChartProps {
    title: string;
    chart: PieProps[];
    data: DataProps;
    allInfo: any;
    type: 'guests' | 'scans';
}

export function Chart(props: ChartProps) {
    const { title, chart, data, allInfo, type } = props;
    const windowWidth = Dimensions.get('window').width;
    const headingSize = windowWidth > 650 ? 'intermediate' : 'medium';
    const { total } = data[type];

    function renderInfos() {
        if (type === 'guests') {
            return (
                <>
                    <ChartInfos
                        total={data.guests.giftTotal}
                        color={theme.colors.chart.guests.gift}
                        title='Presentes'
                        percentage={data.guests?.gift}
                        allInfoItem={allInfo.on.guests}
                    />
                    <ChartInfos
                        total={data.guests.defaultersTotal}
                        color={theme.colors.chart.guests.defaulters}
                        title='Faltosos'
                        percentage={data.guests?.defaulters}
                        secondInfo
                        allInfoItem={allInfo.off.guests}
                    />
                </>
            );
        }

        return (
            <>
                <ChartInfos
                    total={data.scans.correctTotal}
                    color={theme.colors.chart.scans.correct}
                    title='Corretos'
                    percentage={data.scans.correct}
                    allInfoItem={allInfo.on.guests}
                />
                <ChartInfos
                    total={data.scans.incorrectTotal}
                    color={theme.colors.chart.scans.incorrect}
                    title='Incorretos'
                    percentage={data.scans.incorrect}
                    secondInfo
                    allInfoItem={allInfo.off.guests}
                />
                <ChartInfos
                    total={data.scans.refusedTotal}
                    color={theme.colors.chart.scans.refused}
                    title='Recusados'
                    percentage={data.scans.refused}
                    thirdInfo
                    allInfoItem={allInfo.refused?.guests}
                />
            </>
        );
    }

    return (
        <S.Chart>
            <Heading marginBottom={40} color='gray' size={headingSize}>
                {title}
            </Heading>
            <S.PieChartContent>
                <S.PieChartGraphic
                    data={chart}
                    outerRadius='100%'
                    innerRadius='85%'
                    padAngle={0}
                />
                <S.PierChartTotalContainer>
                    <S.PierChartTotal>{total}</S.PierChartTotal>
                    <S.PierChartTotalLabel>Total</S.PierChartTotalLabel>
                </S.PierChartTotalContainer>
                {renderInfos()}
            </S.PieChartContent>
        </S.Chart>
    );
}
