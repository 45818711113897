/* eslint-disable max-lines */
import { NavigationProp, RouteProp } from '@react-navigation/native';
import { Alert, Platform } from 'react-native';
import { useState, useEffect } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { SimpleLineIcons } from '@expo/vector-icons';
import { MenuProvider } from 'react-native-popup-menu';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import AwesomeAlert from 'react-native-awesome-alerts';
import * as DocumentPicker from 'expo-document-picker';
import * as Linking from 'expo-linking';
import { downloadFileFromUri, openDownloadedFile } from 'expo-downloads-manager';
import * as FileSystem from 'expo-file-system';
import { useSelector } from 'react-redux';

import { ErrorMessage } from '@hookform/error-message';
import { useForm } from 'react-hook-form';
import { Heading } from '../../../components/Heading';
import { Event } from '../../../components/EventsGallery';
import { ModalProgress } from '../../../components/ModalProgress';
import { Can } from '../../../components/Can';
import { exportGuests, sendMessage } from '../../../services/guests';
import { uploadCsv, uploadFile } from '../../../services/toolsApi';
// eslint-disable-next-line no-unused-vars
import { Dropdown, OptionProps } from '../../../components/Dropdown';
import { PlaceLoader } from '../../../components/PlaceLoader';
import { deleteEvent, eventBlocked } from '../../../services/events';
import { permissions } from '../../../utils/permissions';

import * as S from './styles';
import { defaultTheme } from '../../../styles/theme';
import { InputHookForm } from '../../../components/InputHookform';
import { Errors } from '../../../components/Errors';
import { Button } from '../../../components/Button';
import { InputSelect } from '../../../components/InputSelect';

interface EventDetailsScreen {
    route: RouteProp<{
        params: {
            event: Event;
        };
    }>;
    navigation: NavigationProp<{}>;
}

interface AlertImportProps{
    color:string,
    title: string,
    message: string
}

const isWeb = Platform.OS === 'web';

export function EventDetailsScreen({ route, navigation }: EventDetailsScreen) {
    const { event } = route.params;
    const { user } = useSelector((state: any) => state.user.user);
    const { control, handleSubmit, reset, formState: { errors } } = useForm();

    const [eventIsBlocked, setEventIsBlocked] = useState(event.blocked_at);
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [openModalLoading, setModaLoading] = useState(false);
    const [exportProgress, setExportProgress] = useState(0);
    const [titleModalLoading, setTitleModalLoading] = useState('');
    const [openAlertImport, setOpenAlertImport] = useState(false);
    const [alertImport, setAlertImport] = useState({} as AlertImportProps);
    const [openModalSuccessDelete, setOpenModalSuccessDelete] = useState(false);
    const [inviteLoading, setInviteLoading] = useState(false);
    const [modalSendMessage, setModalSendMessage] = useState(false);
    const [loadingSendMessage, setLoadingSendMessage] = useState(false);
    const [status, setStatus] = useState('');
    const [type, setType] = useState('');
    const [fileDocument, setFileDocument] = useState<any>();
    const date = new Date(event.date);
    const insets = useSafeAreaInsets();
    const inviteImage = {
        uri: event.invitation,
    };

    function goBackHome() {
        navigation.goBack();
    }

    function handleNavigateGuests() {
        navigation.navigate(
            'GuestsScreen' as never,
            {
                idEvent: event.id,
                event,
            } as never,
        );
    }

    function handleNavigateChart() {
        navigation.navigate(
            'ChartsEventScreen' as never,
            {
                idEvent: event.id,
                event,
            } as never,
        );
    }

    const handleEdit = () => {
        navigation.navigate('EventEditScreen' as never, { event } as never);
    };

    const handleBlock = async () => {
        try {
            const response = await eventBlocked(event.id, event.blocked_at === null);

            if (response.status === 200) {
                setEventIsBlocked(!eventIsBlocked);
                setAlertImport({
                    color: '#98C828',
                    title: `Evento ${eventIsBlocked ? 'habilitado' : 'desabilitado'}`,
                    message: `O evento foi ${eventIsBlocked ? 'habilitado' : 'desabilitado'} com sucesso.`,
                });
                openModalImport();
            }
        } catch (e) {
            // eslint-disable-next-line no-alert
            alert(e);
        }
    };

    const handleNavigateToGome = () => {
        setOpenModalSuccessDelete((prevState) => !prevState);
        navigation.reset({
            index: 0,
            routes: [{ name: 'Home' } as never],
        });
    };

    const handleDelete = async () => {
        openModalDelete();
        await deleteEvent(String(event.id)).then(() => {
            setOpenModalSuccessDelete((prevState) => !prevState);
        });
    };

    const openModalDelete = () => {
        setOpenModalConfirm(!openModalConfirm);
    };

    const openModalImport = () => {
        setOpenAlertImport(!openAlertImport);
    };


    const callback = (downloadProgress) => {
        const progress = downloadProgress.totalBytesWritten
          / downloadProgress.totalBytesExpectedToWrite;
        setExportProgress(progress);
    };

    const handleExportCsv = async () => {
        setTitleModalLoading('Exportando dados');
        setModaLoading(true);
        await exportGuests(event.id).then(async ({ result }) => {

            await downloadFileFromUri(
                result.file,
                `Puzli-App-${event.name.replace(/\s/g, '')}.csv`,
                callback,
            );

            if (Platform.OS === 'web') {
                setModaLoading(false);
                setExportProgress(0);
                Linking.openURL(result.file);
            } else {
                setModaLoading(false);
                setExportProgress(0);
                setTimeout(() => {
                    openDownloadedFile(`${event.name.replace(/\s/g, '')}.csv`);
                }, 500);
            }
        }).catch(() => {
            setExportProgress(0.3);
            setTimeout(() => {
                setModaLoading(false);
                Alert.alert('Aviso', 'Não foi possível realizar a  exportação dos dados. Tente novamente!');
            }, 1000);
        });
    };

    const converteBase64 = async (uri:string) => {
        const base64 = await FileSystem.readAsStringAsync(uri, { encoding: 'base64' });
        return `data:text/csv;base64,${base64}`;
    };

    const openModalSendMessage = () => {
        setModalSendMessage(!modalSendMessage);
    };

    const handleSend = async (data) => {
        setLoadingSendMessage(true);
        const dataSendMessage: any = {
            message: data.description,
            type,
            guestStatus: status,
            idEvent: event.id,
        };
        if (fileDocument?.link) {
            dataSendMessage.file = fileDocument.link;
        }
        const responseMessage = await sendMessage(dataSendMessage);
        setLoadingSendMessage(false);
        setType('');
        setStatus('');
        reset();
        openModalSendMessage();
        setTimeout(() => {
            if (responseMessage?.status === 200) {
                setAlertImport({
                    color: '#98C828',
                    title: 'Sucesso!',
                    message: responseMessage?.message || 'Mensagem enviada!',
                });
            } else {
                setAlertImport({
                    color: '#FF7171',
                    title: 'Algo deu errado!',
                    message: responseMessage?.message || 'Mensagem NÃO enviada!',
                });
            }
            openModalImport();
        }, 500);
    };

    const handleUploadDocuments = async () => {
        const result = await DocumentPicker.getDocumentAsync({
            type: ['application/pdf', 'image/png', 'image/jpeg'],
        });
        if (result.type === 'success') {
            const uri = Platform.OS === 'web' ? result.uri : await converteBase64(result.uri);
            const file = {
                name: result.name,
                type: result.mimeType,
                uri,
            };
            const resultUploadFile = await uploadFile(file);
            setFileDocument({ ...file, link: resultUploadFile.result });
        }
    };

    const handleUploadCsv = async () => {
        const result = await DocumentPicker.getDocumentAsync({
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        if (result.type === 'success') {
            setTitleModalLoading('Enviando informações');
            setExportProgress(0.2);
            setModaLoading(true);
            const uri = Platform.OS === 'web' ? result.uri : await converteBase64(result.uri);
            const file = {
                name: result.uri.split('/').pop(),
                type: 'text/csv',
                uri,
            };

            setTimeout(() => {
                setExportProgress(0.7);
            }, 2000);

            await uploadCsv(file, event.id).then(async (response) => {
                setExportProgress(1);
                if (response.status === 200) {
                    setModaLoading(false);
                    setAlertImport({
                        color: '#98C828',
                        title: 'Sucesso!',
                        message: 'O download do seus dados iniciará automaticamente.',
                    });
                    openModalImport();
                    Linking.openURL(response.result.file);
                } else {
                    setModaLoading(false);
                    setExportProgress(0);
                    setAlertImport({
                        color: '#FF7171',
                        title: 'Algo deu errado!',
                        message: response.message,
                    });
                    openModalImport();
                }
                // await downloadFileFromUri(
                //     response.result.file,
                //     `Puzli-App-${event.name.replace(/\s/g, '')}.rar`,
                //     callback,
                // ).then(() => {
                //     if (Platform.OS === 'web') {
                //         setModaLoading(false);
                //         setExportProgress(0);
                //         Linking.openURL(response.result.file);
                //     } else {
                //         setModaLoading(false);
                //         setExportProgress(0);
                //         setTimeout(() => {
                //             openDownloadedFile(`${event.name.replace(/\s/g, '')}.rar`);
                //         }, 500);
                //     }
                // }).catch(() => {
                //     setModaLoading(false);
                //     setExportProgress(0);
                //     Alert.alert('Aviso', 'Sua solicitação não pode ser concluída. Tente novamente!');
                // });
            }).catch(() => {
                setModaLoading(false);
                setExportProgress(0);
                Alert.alert('Aviso', 'Sua solicitação não pode ser concluída. Tente novamente!');
            });
        }


    };

    const optionsDropdown: OptionProps[] = isWeb ? [
        {
            title: 'Enviar mensagem',
            nameIcon: 'send',
            onSelect: openModalSendMessage,
        },
        {
            title: 'Exportar convidados',
            nameIcon: 'download-cloud',
            onSelect: handleExportCsv,
            typeIcon: 'Feather',
        },
        {
            title: 'Importar em massa',
            nameIcon: 'upload-cloud',
            onSelect: handleUploadCsv,
            typeIcon: 'Feather',
        },
        {
            title: 'Editar',
            nameIcon: 'square-edit-outline',
            onSelect: handleEdit,
        },
        {
            title: eventIsBlocked ? 'Habilitar' : 'Desabilitar',
            nameIcon: 'block-helper',
            onSelect: handleBlock,
        },
        {
            title: 'Excluir',
            nameIcon: 'trash-can-outline',
            onSelect: openModalDelete,
        },
    ] : [
        {
            title: 'Enviar mensagem',
            nameIcon: 'send',
            onSelect: openModalSendMessage,
        },
        {
            title: 'Editar',
            nameIcon: 'square-edit-outline',
            onSelect: handleEdit,
        },
        {
            title: eventIsBlocked ? 'Habilitar' : 'Desabilitar',
            nameIcon: 'block-helper',
            onSelect: handleBlock,
        },
        {
            title: 'Excluir',
            nameIcon: 'trash-can-outline',
            onSelect: openModalDelete,
        },
    ];

    const optionsHost: OptionProps[] = [
        {
            title: 'Exportar convidados',
            nameIcon: 'download-cloud',
            onSelect: handleExportCsv,
            typeIcon: 'Feather',
        },
    ];

    const currentOptionsDropdown = user.type === 'adm' ? optionsDropdown : optionsHost;

    function ErrorsComponent({ message }) {
        return <Errors message={message} />;
    }

    const showInvite = () => {
        setInviteLoading(true);
    };

    useEffect(() => {
        navigation.setOptions({
            title: `Evento | ${event.name}`,
        });
    }, [navigation, route]);

    return (
        <MenuProvider>
            <S.Main>
                <S.Container>
                    <S.PhotoContainer
                        source={{
                            uri: event.front_cover,
                        }}
                        resizeMode='cover'
                    />
                    <S.PhotoContainerBlur intensity={50} tint='light'>
                        <S.Photo
                            source={{
                                uri: event.front_cover,
                            }}
                            resizeMode='cover'
                        />
                        <S.BackContainer onPress={goBackHome} top={insets.top}>
                            <S.BlurGoBackButton intensity={50} tint='light'>
                                <S.BackIcon name='arrow-back-sharp' />
                            </S.BlurGoBackButton>
                        </S.BackContainer>
                    </S.PhotoContainerBlur>
                    <S.BoxContainer>
                        <S.Content>
                            <S.EventBoxTitle>
                                <S.BoxHeadingTitle>
                                    <Heading
                                        color='gray'
                                        size={isWeb ? 'large' : 'medium'}
                                    >
                                        {event.name}
                                    </Heading>
                                </S.BoxHeadingTitle>
                                <S.EventBoxButtons>
                                    <Can role={permissions.permissionHostAndMaster}>
                                        <S.ButtonChart
                                            onPress={handleNavigateChart}
                                        >
                                            <S.ChartIcon name='pie-chart' />
                                        </S.ButtonChart>
                                    </Can>
                                    <Can role={permissions.permissionsAll}>
                                        <S.ButtonUser
                                            onPress={handleNavigateGuests}
                                        >
                                            <S.ChartIcon name='user' />
                                        </S.ButtonUser>
                                    </Can>
                                    <Can role={permissions.permissionHostAndMaster}>
                                        <Dropdown
                                            options={currentOptionsDropdown}
                                            icon={(
                                                <SimpleLineIcons
                                                    name='options-vertical'
                                                    size={20}
                                                    color='#B2BEC3'
                                                />
                                            )}
                                        />
                                    </Can>
                                </S.EventBoxButtons>
                            </S.EventBoxTitle>
                            <S.BoxDate>
                                <S.TextDate>
                                    {format(
                                        date.setDate(date.getDate() + 1),
                                        "EEEE, dd 'de' MMMM 'de' yyyy",
                                        { locale: ptBR },
                                    )}
                                </S.TextDate>
                                <S.TextHours>{event.time}</S.TextHours>
                            </S.BoxDate>
                            <S.ContentEvent>
                                <S.ContentDescription>
                                    <Heading
                                        color='gray'
                                        size={
                                            isWeb ? 'intermediate' : 'medium'
                                        }
                                    >
                                        Descrição
                                    </Heading>
                                    <S.TextField>
                                        {event.description}
                                    </S.TextField>
                                </S.ContentDescription>
                                <S.ContentLink>
                                    <Heading color='gray' size='large'>
                                        {event.dual_link ? 'Link para grupo de convidados' : 'Link'}
                                    </Heading>
                                    <S.TextField selectable>
                                        {event.link_group_users}
                                    </S.TextField>
                                </S.ContentLink>
                                {!event.dual_link ? null : (
                                    <S.ContentLink>
                                        <Heading color='gray' size='large'>
                                            Link para convidado único
                                        </Heading>
                                        <S.TextField selectable>
                                            {event.link_unique_user}
                                        </S.TextField>
                                    </S.ContentLink>
                                )}
                                <S.ContentInvite>
                                    <Heading color='gray' size='large'>
                                        Convite
                                    </Heading>
                                    <PlaceLoader
                                        width={250}
                                        height={350}
                                        border={10}
                                        mt={40}
                                        align='center'
                                        visible={inviteLoading}
                                    >
                                        <S.InvitePhoto
                                            source={inviteImage}
                                            onLoad={showInvite}
                                            resizeMode='stretch'
                                        />
                                    </PlaceLoader>
                                </S.ContentInvite>
                            </S.ContentEvent>
                        </S.Content>
                    </S.BoxContainer>
                </S.Container>
            </S.Main>
            <AwesomeAlert
                show={openModalConfirm}
                title='Excluir Evento'
                message={`Tem certeza que deseja excluir ${event.name}?`}
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showCancelButton
                showConfirmButton
                cancelText='Cancelar'
                confirmText='Confirmar'
                confirmButtonColor='#FF7171'
                onCancelPressed={openModalDelete}
                onConfirmPressed={handleDelete}
            />

            <AwesomeAlert
                show={openModalSuccessDelete}
                title='Evento excluído com succeso'
                message='O evento foi excluído com sucesso!'
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showConfirmButton
                confirmText='Ok'
                confirmButtonColor={defaultTheme.colors.green}
                onConfirmPressed={handleNavigateToGome}
            />

            <AwesomeAlert
                show={openAlertImport}
                title={alertImport.title}
                message={alertImport.message}
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showConfirmButton
                confirmText='Confirmar'
                confirmButtonColor={alertImport.color}
                onConfirmPressed={openModalImport}
            />
            <ModalProgress title={titleModalLoading} isOpen={openModalLoading} progress={exportProgress} />
            <S.ModalArea isVisible={modalSendMessage} backdropOpacity={0.8} onBackdropPress={openModalSendMessage}>
                <S.ModalContainer>
                    <S.ContentModal>
                        <Heading color='gray' size='small'>
                            Enviar mensagem
                        </Heading>
                        <InputSelect
                            list={[
                                {
                                    value: 'whatsapp',
                                    label: 'Whatsapp',
                                },
                                {
                                    value: 'email',
                                    label: 'E-mail',
                                },
                            ]}
                            setProps={setType}
                            initialValue={type}
                            label='Tipo'
                            errors={errors.type}
                        />
                        <ErrorMessage
                            errors={errors}
                            name='type'
                            render={ErrorsComponent}
                        />
                        <InputSelect
                            list={[
                                {
                                    value: 'pending',
                                    label: 'Pendente',
                                },
                                {
                                    value: 'approved',
                                    label: 'Aprovado',
                                },
                                {
                                    value: 'disapproved',
                                    label: 'Reprovado',
                                },
                                {
                                    value: 'isVerified',
                                    label: 'Presentes',
                                },
                            ]}
                            setProps={setStatus}
                            initialValue={status}
                            label='Status'
                            errors={errors.status}
                        />
                        <ErrorMessage
                            errors={errors}
                            name='status'
                            render={ErrorsComponent}
                        />
                        <InputHookForm
                            errors={errors.description}
                            name='description'
                            control={control}
                            label='Mensagem'
                            type='descriptions'
                            placeholder='Digite sua mensagem'
                        />
                        <ErrorMessage
                            errors={errors}
                            name='description'
                            render={ErrorsComponent}
                        />
                        <S.TouchableOpacity onPress={handleUploadDocuments}>
                            <S.Text>Clique aqui para adicionar o arquivo</S.Text>
                            <S.TextInfo>
                                Formatos aceitos: JPEG/JPG, PNG e PDF.
                            </S.TextInfo>
                        </S.TouchableOpacity>
                        {!fileDocument ? null : (
                            <S.TextSelect>
                                {`Arquivo selecionado: ${fileDocument?.name}`}
                            </S.TextSelect>
                        )}
                        <S.ContainerButton>
                            <Button
                                title='ENVIAR'
                                loading={loadingSendMessage}
                                disabled={loadingSendMessage}
                                onClick={handleSubmit(handleSend)}
                            />
                        </S.ContainerButton>
                    </S.ContentModal>
                </S.ModalContainer>
            </S.ModalArea>
        </MenuProvider>
    );
}
