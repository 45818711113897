import * as React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useSelector } from 'react-redux';

import QrIcon from '../../assets/images/svgs/qrCodeTabMenu';
import UserIcon from '../../assets/images/svgs/userTabMenu';
import CalendarsIcon from '../../assets/images/svgs/calendarTab';
import AdmIcon from '../../assets/images/svgs/usersSvg';
import EventsScreen from '../../screens/Events/EventsScreen';
import { QrCodeScreen } from '../../screens/QrCode/QrCodeScreen';
import { Whatsapp } from '../../screens/Whatsapp';
import { HostsScreen } from '../../screens/Hosts/HostsScreen';
import { AdministratorsScreen } from '../../screens/Administrators/AdministratorsScreen';
import { defaultTheme as theme } from '../../styles/theme';

const { Navigator, Screen } = createBottomTabNavigator();

export function MainTabRoutes() {
    const user = useSelector((state: any) => state?.user.user);

    function tabBarIcon(component: any) {
        return { tabBarIcon: component };
    }

    function renderCalendarsIcon(item: any) {
        const { color } = item;
        return (
            <CalendarsIcon color={color} />
        );
    }

    function renderUserIcon(item: any) {
        const { color } = item;
        return (
            <UserIcon color={color} />
        );

    }

    function renderAdmIcon(item: any) {
        const { color } = item;
        return (
            <AdmIcon color={color} />
        );
    }

    function renderQrIcon(item: any) {
        const { color } = item;
        return (
            <QrIcon color={color} />
        );
    }

    function renderWhatsappIcon(item: any) {
        const { color } = item;
        return (
            <MaterialCommunityIcons name='whatsapp' size={30} color={color} />
        );
    }

    function renderRoutesWithUser() {
        if (user?.user.permission === 'receptionist') {
            return null;
        }
        return (
            <>
                <Screen
                    options={tabBarIcon(renderCalendarsIcon)}
                    name='Eventos'
                    component={EventsScreen}
                />
                <Screen
                    options={tabBarIcon(renderUserIcon)}
                    name='Anfitriões'
                    component={HostsScreen}
                />
                <Screen
                    options={tabBarIcon(renderAdmIcon)}
                    name='Administradores'
                    component={AdministratorsScreen}
                />
                <Screen
                    options={tabBarIcon(renderQrIcon)}
                    name='Qr Code'
                    component={QrCodeScreen}
                />
                <Screen
                    options={tabBarIcon(renderWhatsappIcon)}
                    name='Whatsapp'
                    component={Whatsapp}
                />
            </>
        );
    }

    function renderRoutesWithUserReceptionist() {
        if (user?.user.permission !== 'receptionist') {
            return null;
        }
        return (
            <>
                <Screen
                    options={tabBarIcon(renderCalendarsIcon)}
                    name='Eventos'
                    component={EventsScreen}
                />
                <Screen
                    options={tabBarIcon(renderQrIcon)}
                    name='Qr Code'
                    component={QrCodeScreen}
                />
            </>
        );
    }

    return (
        <Navigator
            screenOptions={{
                tabBarShowLabel: false,
                headerShown: false,
                tabBarActiveTintColor: theme.colors.purpleDark,
                tabBarInactiveTintColor: theme.colors.grayLight,
                tabBarStyle: {},
            }}
        >
            {renderRoutesWithUser()}
            {renderRoutesWithUserReceptionist()}
        </Navigator>
    );
}
