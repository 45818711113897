import styled from 'styled-components/native';
import { Dimensions, StatusBar, Platform } from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import { defaultTheme as theme } from '../../styles/theme';

const windowWidth = Dimensions.get('window').width;

const isWeb = Platform.OS === 'web';

interface StatusWhatsappProps{
    connected:boolean
}

export const Safe = styled.SafeAreaView`
    flex: 1;
    background-color: ${theme.colors.white};
    ${StatusBar.currentHeight && `padding-top:${StatusBar.currentHeight}px`};
`;

export const Container = styled.View`
    padding: ${isWeb && windowWidth > 650
        ? '5.062rem 8.625rem 2.312rem 8.625rem'
        : `${RFValue(37)}px ${RFValue(37)}px ${RFValue(10)}px ${RFValue(
            37,
        )}px}`};
    flex: 1;
`;

export const Content = styled.View`
    flex: 1;
    justify-content: center;
`;

export const Header = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 26px;
`;

export const QrCodeView = styled.View<StatusWhatsappProps>`
    width: ${windowWidth > 650 ? `${RFValue(150)}px` : `${RFValue(200)}px`};
    height: ${windowWidth > 650 ? `${RFValue(150)}px` : `${RFValue(200)}px`};
    border: 3px solid ${theme.colors.purpleDark};
    border-radius: 10px;
    /* padding: 5px; */
    box-shadow: 5px 5px 6px #00000029;
    align-self: center;
    margin-top: -100px;
    background-color: #fff;
    ${(props) => props.connected && 'padding:10px'}
`;

export const QrCodeImg = styled.Image`
    width: 100%;
    height: 100%;
    border-radius: 10px;
`;

export const Shadow = Platform.OS === 'android' && {
    shadowColor: '#000',
    shadowOffset: {
        width: 100,
        height: 8,
    },
    shadowOpacity: 0.1,
    shadowRadius: 50,

    elevation: 10,
};

export const StatusWhatsappContainer = styled.View`
    align-self: center;
    margin-top: 20px;
    flex-direction: row;
    align-items: center;
`;


export const StatusWhatsapp = styled.View<StatusWhatsappProps>`
    background-color: ${(props) => (props.connected ? theme.colors.green : theme.colors.red)};
    height: 15px;
    width: 15px;
    margin-right: 10px;
    border-radius: 7.5px;
`;

export const StatusWhatsappText = styled.Text`
    font-size: 16px;
    font-family: ${theme.font.regular};
    color: ${theme.colors.gray};
`;
