import React, { useState } from 'react';
import { ActivityIndicator } from 'react-native';

import * as S from './styles';
import { ButtonOptions } from '../ButtonOptions/index';
import { ErrorAlert } from '../../../../../components/ErrorAlert';
import { guestConfirmPresence, guestUpdateStatus } from '../../../../../services/guests';
import { PlaceLoader } from '../../../../../components/PlaceLoader';
import { Can } from '../../../../../components/Can';

import AwaitIcon from '../../../../../assets/images/svgs/awaitSvg';
import ApprovedIcon from '../../../../../assets/images/svgs/approvedSvg';
import DisapprovedIcon from '../../../../../assets/images/svgs/disapprovedSvg';
import confirmPresenceTrueIcon from '../../../../../assets/images/svgs/confirmPresenceTrue.png';
import confirmPresenceFalseIcon from '../../../../../assets/images/svgs/confirmPresenceFalse.png';
import QrCodeIcon from '../../../../../assets/images/qrCode/qr-code.png';
import photoUserEmpty from '../../../../../assets/images/user/user-photo-hosts.png';
import { permissions } from '../../../../../utils/permissions';

export interface GuestsProps {
    id: number;
    name: string;
    email: string;
    telephone: string;
    photo: string;
    status: 'pending' | 'approved' | 'disapproved';
    is_verified: boolean;
    main_name: string;
    childrenGuest: string;
    confirm_presence: boolean;
}

interface CardGuestsProps {
    guest: GuestsProps;
    navigation: any;
    event: any;
    getGuests: () => void;
    connected: boolean;
    openModalDelete: () => void;
}

export function CardGuest(props: CardGuestsProps) {
    const { guest, navigation, event, getGuests, connected, openModalDelete } = props;

    const [showMoreIsVisible, setShowMoreIsVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [imageIsLoading, setImageIsLoading] = useState(false);

    const photo = String(guest.photo);
    const guestImage = { uri: photo || photoUserEmpty };
    const showMoreText = showMoreIsVisible ? 'Mostrar menos' : 'Mostrar mais';
    const showMoreIcon = showMoreIsVisible ? 'up' : 'down';

    const handleOpenShowMored = () => {
        setShowMoreIsVisible(!showMoreIsVisible);
    };

    const handleClick = () => {
        navigation.navigate('GuestsDetailsScreen', { guest, event });
    };

    const handleApproved = async () => {
        if (connected) {
            setLoading(true);
            await guestUpdateStatus('approved', [guest.id]).then((response) => {
                if (response.status === 200) {
                    getGuests();
                    setLoading(false);
                }
            });
        } else {
            ErrorAlert(
                'Aviso',
                'Sua solicitação não pode ser concluída. O Whatsapp está desconectado!',
            );
            navigation.navigate('Whatsapp');
        }
    };

    const handleDisapproved = async () => {
        setLoading(true);
        await guestUpdateStatus('disapproved', [guest.id]).then((response) => {
            if (response.status === 200) {
                setLoading(false);
                getGuests();
            }
        });
    };

    const handleConfirmPresence = async () => {
        setLoading(true);
        await guestConfirmPresence(guest.id).then((response) => {
            if (response.status === 200) {
                setLoading(false);
                getGuests();
            }
        });
    };

    const handlePending = async () => {
        setLoading(true);
        await guestUpdateStatus('pending', [guest.id]).then((response) => {
            if (response.status === 200) {
                setLoading(false);
                getGuests();
            }
        });
    };

    const showImage = () => {
        setImageIsLoading(true);
    };

    const renderIconPresence = () => {
        return <S.ImagePresence source={guest?.confirm_presence ? confirmPresenceTrueIcon : confirmPresenceFalseIcon} />;
    };

    const renderIcon = () => {
        if (guest.is_verified) {
            return <S.QrCodeIcon source={QrCodeIcon} />;
        }
        if (guest.status === 'pending') {
            return <AwaitIcon />;
        }
        if (guest.status === 'approved') {
            return <ApprovedIcon />;
        }
        return <DisapprovedIcon />;
    };

    const renderOptions = () => {
        const renderDisapproved = () => {
            if (guest.status !== 'disapproved') {
                return (
                    <ButtonOptions
                        type='disapproved'
                        onPress={handleDisapproved}
                    />
                );
            }
            return null;
        };

        const renderApproved = () => {
            if (guest.status !== 'approved') {
                return (
                    <ButtonOptions type='approved' onPress={handleApproved} />
                );
            }
            return null;
        };

        const renderConfirmePresence = () => {
            return <ButtonOptions type='presence' onPress={handleConfirmPresence} />;
        };

        const renderPending = () => {
            if (guest.status !== 'pending') {
                return <ButtonOptions type='pending' onPress={handlePending} />;
            }
            return null;
        };

        const renderDelete = () => {
            return <ButtonOptions type='delete' onPress={openModalDelete} />;
        };

        if (!showMoreIsVisible) {
            return null;
        }

        return (
            <S.Options>
                {renderDelete()}
                {renderDisapproved()}
                {renderApproved()}
                {renderPending()}
                {guest.confirm_presence ? null : renderConfirmePresence()}
            </S.Options>
        );
    };

    const renderLoading = () => {
        if (loading) {
            return (
                <S.Options>
                    <ActivityIndicator color='#5209A8' size='small' />
                </S.Options>
            );
        }
        return renderOptions();
    };

    return (
        <S.Container
            isOpen={showMoreIsVisible}
            style={S.Shadow}
            onPress={handleClick}
        >
            <S.Box>
                <PlaceLoader
                    visible={imageIsLoading}
                    width={46}
                    height={46}
                    mr={12}
                    border={4}
                >
                    <S.Image onLoad={showImage} source={guestImage} />
                </PlaceLoader>
                <S.Content>
                    <Can role={permissions.recepcionist}>
                        <S.Name>{guest.name}</S.Name>
                    </Can>
                    <Can role={permissions.permissionHostAndMaster}>
                        <S.Name numberOfLines={1}>{guest.name}</S.Name>
                        {/* <S.Email numberOfLines={1}>{guest.email}</S.Email> */}
                    </Can>
                    <Can role={permissions.permissionHostAndMaster}>
                        <S.Phone>{guest.telephone}</S.Phone>
                    </Can>
                    {!guest.main_name ? null : (
                        <Can role={permissions.permissionHostAndMaster}>
                            <S.MainName>
                                {`Convidado por: ${guest.main_name}`}
                            </S.MainName>
                        </Can>
                    )}
                    {!guest.childrenGuest ? null : (
                        <Can role={permissions.permissionHostAndMaster}>
                            <S.MainName>
                                {`Acompanhante(s): ${guest.childrenGuest}`}
                            </S.MainName>
                        </Can>
                    )}
                </S.Content>
                <S.IconStatusContainer>
                    {renderIcon()}
                    {renderIconPresence()}
                </S.IconStatusContainer>
            </S.Box>
            {renderLoading()}
            <Can role={permissions.permissionHostAndMaster}>
                <S.ShowMore onPress={handleOpenShowMored}>
                    <S.ShowMoreText>{showMoreText}</S.ShowMoreText>
                    <S.ShowMoreIcon name={showMoreIcon} />
                </S.ShowMore>
            </Can>
        </S.Container>
    );
}
