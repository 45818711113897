import { MaterialCommunityIcons, SimpleLineIcons, Feather } from '@expo/vector-icons';
import { Dimensions, Platform } from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';

import { defaultTheme as theme } from '../../styles/theme';

const isWeb = Platform.OS === 'web';
const windowWidth = Dimensions.get('window').width;

interface OptionsMenuIconProps {
    typeIcon: string
}

export const ButtonOptionsIconTrigger = styled(SimpleLineIcons)`
    color: ${theme.colors.gray};
    font-size: ${isWeb && windowWidth > 650 ? '1.125rem' : `${RFValue(16)}px`};
`;

export const OptionsMenuContainer = styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

export const OptionsMenuText = styled.Text`
    font-size: ${isWeb && windowWidth > 650 ? '0.9375rem' : `${RFValue(12)}px`};
    font-family: ${theme.font.normal};
    color: ${theme.colors.gray};
`;

export const OptionsMenuIcon = styled(MaterialCommunityIcons)`
    color: ${theme.colors.grayLight};
    margin-right: 7px;
`;

export const OptionsMenuIconFeather = styled(Feather)`
    color: ${theme.colors.grayLight};
    margin-right: 7px;
`;
