import React, { useState, useEffect } from 'react';
import { Platform } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { Heading } from '../../components/Heading';
import * as S from './styled';
import { ButtonDrawer } from '../../components/ButtonDrawer';
import { checkWhatsapp } from '../../services/client';
import QrCodeImg from '../../assets/images/qr-code.png';

const isWeb = Platform.OS === 'web';

interface WhatsappStatusProps {
    connected: boolean;
    qrcode: string;
}

export function Whatsapp() {
    // consts
    let intervalId;
    // states
    const [whatsappStatus, setWhatsappStatus] = useState(
        {} as WhatsappStatusProps,
    );
    const isFocused = useIsFocused();

    async function getStatusWhatsapp() {
        try {
            const response = await checkWhatsapp();
            setWhatsappStatus(response.result);
        } catch (e) {
            return null;
        }
        return null;
    }

    useEffect(() => {
        if (!whatsappStatus.connected) {
            intervalId = setInterval(() => {
                getStatusWhatsapp();
            }, 4000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [whatsappStatus]);

    useEffect(() => {
        if (isFocused) {
            getStatusWhatsapp();
        }
    }, [isFocused]);

    return (
        <S.Safe>
            <S.Container>
                <S.Header>
                    {isWeb ? <ButtonDrawer /> : null}
                    <Heading color='purpleDark' size='large'>
                        Whatsapp
                    </Heading>
                </S.Header>
                <S.Content>
                    <S.QrCodeView
                        connected={whatsappStatus?.connected}
                        style={S.Shadow}
                    >
                        {whatsappStatus?.connected ? (
                            <S.QrCodeImg
                                resizeMode='cover'
                                source={QrCodeImg}
                            />
                        ) : (
                            <S.QrCodeImg
                                resizeMode='cover'
                                source={{
                                    uri: whatsappStatus.qrcode,
                                }}
                            />
                        )}
                    </S.QrCodeView>
                    <S.StatusWhatsappContainer>
                        <S.StatusWhatsapp
                            connected={whatsappStatus?.connected}
                        />
                        <S.StatusWhatsappText>
                            {whatsappStatus?.connected
                                ? 'Conectado'
                                : 'Desconectado'}
                        </S.StatusWhatsappText>
                    </S.StatusWhatsappContainer>
                </S.Content>
            </S.Container>
        </S.Safe>
    );
}
