import { StatusBar, Platform, TouchableOpacityProps, Dimensions } from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';

import { Feather } from '@expo/vector-icons';
import { defaultTheme as theme } from '../../../styles/theme';

const isWeb = Platform.OS === 'web';
const windowWidth = Dimensions.get('window').width;

interface VacinityContainerProps {
    mt: number;
}

interface ButtonImageProps extends TouchableOpacityProps {
    height: number
}

export const Safe = styled.SafeAreaView`
    flex: 1;
    ${StatusBar.currentHeight && `padding-top:${StatusBar.currentHeight}px`};
`;

interface ButtonImageProps extends TouchableOpacityProps {
    height: number;
    width?: number
}

export const Container = styled.ScrollView`
    flex: 1;
    padding: ${isWeb && windowWidth > 650 ? '4.875rem 8.265rem 2.437rem 8.265rem' : `${RFValue(35)}px ${RFValue(36)}px ${RFValue(0)}px ${RFValue(36)}px`};
`;

export const Content = styled.View`
    background-color: ${isWeb && windowWidth > 650 ? '#fff' : ''};
    border: ${isWeb && windowWidth > 650 ? 'solid 1px #B2BEC3' : 'none'};
    width: ${isWeb && windowWidth > 1100 ? '834px' : '100%'};
    border-radius: 10px;
    padding: ${isWeb && windowWidth > 650 ? '85px 52px 50px 52px' : '20px 0px 50px 0px '};
    align-self: center;
`;

export const ProgressContainer = styled.View`
    margin-top: ${RFValue(16)}px;
    margin-bottom: ${RFValue(20)}px;
    width: 100%;
`;

export const Form = styled.ScrollView`
    margin-top: ${RFValue(20)}px;
    width: 100%;
`;


export const ViewInput = styled.View`
    width: 49%;
`;

export const InputsInLine = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`;

export const VacinityContainer = styled.View<VacinityContainerProps>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: ${({ mt }) => `${RFValue(mt)}px`};
`;

export type ContainerButtonProps = {
    isFinal?: boolean
}

export const ContainerButton = styled.View<ContainerButtonProps>`
    ${isWeb && 'width: 324px; align-self:center'}
    margin-bottom: ${(props) => (props.isFinal ? `${RFValue(40)}px` : `${RFValue(10)}px`)};
    padding: ${isWeb && windowWidth > 650 ? '0px' : `0px ${RFValue(36)}px`};
    margin-top: ${(props) => (props.isFinal ? `${RFValue(20)}px` : `${RFValue(82)}px`)};
`;


export const ButtonImageEvent = styled.TouchableOpacity<ButtonImageProps>`
    height: ${({ height }) => height}px;
    width: ${({ width }) => (width && isWeb ? `${width}px` : '100%')};
    align-self: center;
    margin-top: ${RFValue(26)}px;
    margin-bottom: 5px;
`;

export const ButtonLabel = styled.Text`
    text-align: center;
    font-family: ${theme.font.regular};
    font-size: ${isWeb && windowWidth > 650 ? '0.75rem' : `${RFValue(12)}px`};
    margin-bottom: ${RFValue(10)}px;
    color: ${theme.colors.gray};
`;

export const IconCan = styled(Feather)`
    font-size: 18px;
    color: ${theme.colors.grayLight};
    position: absolute;
    bottom: 12px;
    right: 12px;
`;

export const ImageButton = styled.Image`
    height: 100%;
    width: 100%;
    border-radius: 5px;
`;
