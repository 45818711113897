import React, { useEffect } from 'react';

import { useSafeAreaInsets } from 'react-native-safe-area-context';
import * as S from './styles';

import { CardUserInfo } from './components/CardUserInfo';

import bottomImg from '../../../assets/images/qr-codes-desktop-bottom.png';

interface QrCodeResultScreenProps{
    navigation?: any,
    route?: any
}

export function QrCodeResultScreen({ navigation, route }:QrCodeResultScreenProps) {
    const { email, name, photo, telephone, event } = route.params;

    const insets = useSafeAreaInsets();

    function handleGoBack() {
        navigation.navigate('Qr Code', { eventIdScan: event[0].id });
    }

    useEffect(() => {
        navigation.setOptions({
            title: `Convidado | ${name}`,
        });
    }, [navigation, name]);

    return (
        <S.Safe>
            <S.GoBackButton top={insets.top} onPress={handleGoBack}>
                <S.BlurGoBackButton intensity={50} tint='light'>
                    <S.GoBackButtonIcon name='close' />
                </S.BlurGoBackButton>
            </S.GoBackButton>
            <S.ImageEvent source={{ uri: event[0].front_cover }} />

            <S.Content>
                <S.ImageUser source={{ uri: photo }} />
                <S.NameUser>{name}</S.NameUser>
                <CardUserInfo title='Nome' info={name} />
                <CardUserInfo title='Email' info={email} />
                <CardUserInfo title='Telefone' info={telephone} />
            </S.Content>
            <S.ImageBottom source={bottomImg} />
        </S.Safe>
    );
}
