/* eslint-disable max-lines */
import { useEffect, useState } from 'react';
import { NavigationProp } from '@react-navigation/native';
import * as Progress from 'react-native-progress';
import * as ImagePicker from 'expo-image-picker';
import { Platform, Switch, ScrollView } from 'react-native';
import AwesomeAlert from 'react-native-awesome-alerts';
import { ErrorMessage } from '@hookform/error-message';
import { useForm } from 'react-hook-form';
import { listCategories, createEvent } from '../../../services/events';
import { uploadFile } from '../../../services/toolsApi';
import { Header } from '../../../components/Header';
import { Heading } from '../../../components/Heading';
import { Button } from '../../../components/Button';
import { Errors } from '../../../components/Errors';
import { InputSelect } from '../../../components/InputSelect';
import { InputHookForm } from '../../../components/InputHookform';
import mobileInvitation from '../../../assets/images/event/mobileInvitation/invitationEmpty.png';
import mobileCover from '../../../assets/images/event/mobileCover/mobileEmpty.png';
import webInvite from '../../../assets/images/event/webInvite.png';
import webCover from '../../../assets/images/event/webCover.png';
import { Loading } from '../../../components/Loading';
import { LoadingPhoto } from '../../../components/LoadingPhoto';
import { defaultTheme as theme } from '../../../styles/theme';
import * as S from './styles';

const isWeb = Platform.OS === 'web';

interface AddEventScreenProps {
    navigation: NavigationProp<{}>;
}

export function AddEventScreen({ navigation }: AddEventScreenProps) {
    const [idCategory, setIdCategory] = useState(0);
    const [frontCoverImage, setFrontCoverImage] = useState('');
    const [frontCoverImageUrl, setFrontCoverImageUrl] = useState('');
    const [frontCoverImageLoading, setFrontCoverImageLoading] = useState(false);
    const [invitationImage, setInvitationImage] = useState('');
    const [invitationImageUrl, setInvitationImageUrl] = useState('');
    const [invitationImageLoading, setInvitationImageLoading] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);
    const [isPhoto, setIsPhoto] = useState(false);
    const [isGuestGroup, setIsGuestGroup] = useState(false);
    const [isEmail, setIsEmail] = useState(false);
    const [isRg, setIsRg] = useState(false);
    const [isCpf, setIsCpf] = useState(false);
    const [isAddress, setIsAddress] = useState(false);
    const [isNumberShoe, setIsNumberShoe] = useState(false);
    const [isProfession, setIsProfession] = useState(false);
    const [isOffice, setIsOffice] = useState(false);
    const [isBusiness, setIsBusiness] = useState(false);
    const [dualLink, setDualLink] = useState(false);
    const [buttonCreateDisabled, setButtonCreateDisabled] = useState(true);
    const [progress, setProgress] = useState(0.5);
    const [screen, setScreen] = useState(1);
    const [buttonTitle, setButtonTitle] = useState('CONTINUAR');
    const [openModalNotification, setOpenModalNotification] = useState(false);
    const [eventName, setEventName] = useState('');
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingCreateEvent, setLoadingCreateEvent] = useState(false);
    const toggleSwitch = () => setIsEnabled((previousState) => !previousState);
    const toggleIsPhoto = () => setIsPhoto((previousState) => !previousState);
    const toggleSwitchGroup = () => setIsGuestGroup((previousState) => !previousState);
    const toggleSwitchEmail = () => setIsEmail((previousState) => !previousState);
    const toggleSwitchRg = () => setIsRg((previousState) => !previousState);
    const toggleSwitchCpf = () => setIsCpf((previousState) => !previousState);
    const toggleSwitchAddress = () => setIsAddress((previousState) => !previousState);
    const toggleSwitchNumberShoe = () => setIsNumberShoe((previousState) => !previousState);
    const toggleSwitchProfession = () => setIsProfession((previousState) => !previousState);
    const toggleSwitchOffice = () => setIsOffice((previousState) => !previousState);
    const toggleSwitchBusiness = () => setIsBusiness((previousState) => !previousState);
    const toggleSwitchDualLink = () => setDualLink((previousState) => !previousState);

    const { control, handleSubmit, formState: { errors }, setError, clearErrors } = useForm();

    const thumbColorVaccination = isEnabled ? '#7630BE' : '#f4f3f4';
    const thumbColorPhoto = isPhoto ? '#7630BE' : '#f4f3f4';
    const thumbColorGroup = isGuestGroup ? '#7630BE' : '#f4f3f4';
    const thumbColorEmail = isEmail ? '#7630BE' : '#f4f3f4';
    const thumbColorRg = isRg ? '#7630BE' : '#f4f3f4';
    const thumbColorCpf = isCpf ? '#7630BE' : '#f4f3f4';
    const thumbColorAddress = isAddress ? '#7630BE' : '#f4f3f4';
    const thumbColorNumberShoe = isNumberShoe ? '#7630BE' : '#f4f3f4';
    const thumbColorProfession = isProfession ? '#7630BE' : '#f4f3f4';
    const thumbColorOffice = isOffice ? '#7630BE' : '#f4f3f4';
    const thumbColorBusiness = isBusiness ? '#7630BE' : '#f4f3f4';
    const thumbColorDualLink = dualLink ? '#7630BE' : '#f4f3f4';

    const switchConfig = {
        trackColor: { false: '#767577', true: 'rgba(118, 48, 190, 0.5)' },
        styles: {
            transform: [
                { scaleX: 0.8 },
                { scaleY: 0.8 },
            ],
        },
    };

    const buttonCreateIsDisabled = () => {
        if (frontCoverImageUrl === '' || invitationImageUrl === '') {
            setButtonCreateDisabled(true);
            return true;
        }
        setButtonCreateDisabled(false);
        return false;
    };

    const pickImageFrontCover = async () => {
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
        });

        if (!result.cancelled) {
            setFrontCoverImageLoading(true);
            setFrontCoverImage(result.uri);
            const file = {
                name: result.uri.split('/').pop(),
                type: 'image/jpeg',
                uri: result.uri,
            };
            const response = await uploadFile(file);
            if (response.status === 200) {
                setFrontCoverImageLoading(false);
                setFrontCoverImageUrl(response.result);
            }
        }
    };

    const pickImageInvitation = async () => {
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
        });

        if (!result.cancelled) {
            setInvitationImageLoading(true);
            setInvitationImage(result.uri);
            const file = {
                name: result.uri.split('/').pop(),
                type: 'image/jpeg',
                uri: result.uri,
            };
            const response = await uploadFile(file);
            if (response.status === 200) {
                setInvitationImageLoading(false);
                setInvitationImageUrl(response.result);
            }
        }
    };

    const goBack = () => {
        if (screen > 1) {
            setScreen(1);
            setProgress(0.5);
            setButtonTitle('CONTINUAR');
        } else {
            navigation.reset({ index: 0, routes: [{ name: 'Home' } as never] });
        }
    };

    const onError = () => {
        if (Number(idCategory) === 0) {
            setError('idCategory', {
                type: 'validate',
                message: 'Selecione uma categoria',
            });
        }
    };

    const handleContinue = (data) => {
        const dateFormated = formattedDate(data.date);
        const isBigger = new Date(`${dateFormated}`).setHours(24, 0, 0, 0)
            >= new Date().setHours(0, 0, 0, 0);

        if (!isBigger) {
            setError('date', { type: 'validate', message: 'Data inválida' });
            return null;
        }

        if (screen <= 1) {
            setScreen(2);
            setProgress(1);
            setButtonTitle('FINALIZAR');
        } else {
            return null;
        }

        return null;
    };

    const formattedDate = (value: string): string => {
        const [day, month, year] = value.split('/');
        return `${year}-${month}-${day}`;
    };

    const handleCreateEvent = async (data) => {
        setLoadingCreateEvent(true);
        const event = {
            ...data,
            date: formattedDate(data.date),
            frontCover: frontCoverImageUrl,
            invitation: invitationImageUrl,
            idCategory: Number(idCategory),
            isVaccination: isEnabled,
            isPhotoGuests: isPhoto,
            isGuestGroup,
            isEmail,
            isRg,
            isCpf,
            isAddress,
            isNumberShoe,
            isProfession,
            isOffice,
            isBusiness,
            dualLink,
        };
        createEvent(event).then((response) => {
            if (response.status === 201) {
                setLoadingCreateEvent(false);
                setOpenModalNotification(true);
                setEventName(data.name);
            } else {
                setLoadingCreateEvent(false);
            }
        });
    };

    function handleNavigationHome() {
        navigation.reset({ index: 0, routes: [{ name: 'Home' } as never] });
    }

    function ErrorsComponent({ message }) {
        return <Errors message={message} />;
    }

    async function getCategories() {
        await listCategories().then((response) => {
            const categoriesFormated = response.result.list.map((e) => {
                return {
                    value: String(e.id),
                    label: e.name,
                };
            });
            setCategories(categoriesFormated);
            setLoading(false);
        });
    }

    useEffect(() => {
        navigation.setOptions({
            title: 'Adicionar evento',
        });
    }, [navigation]);

    useEffect(() => {
        getCategories();
    }, []);
    useEffect(() => {
        clearErrors('idCategory');
        buttonCreateIsDisabled();
    }, [idCategory, frontCoverImageUrl, invitationImageUrl]);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <S.Safe>
                    <S.Container>
                        <Header
                            handleGoback={goBack}
                            title='Criar Evento'
                            navigation={navigation}
                        />
                        <S.ProgressContainer>
                            <Progress.Bar
                                progress={progress}
                                color={theme.colors.purpleDark}
                                height={6}
                                borderRadius={10}
                                width={null}
                            />
                        </S.ProgressContainer>
                        <S.Content>
                            {screen === 1 ? (
                                <>
                                    <Heading color='gray' size='intermediate'>
                                        Informações do evento
                                    </Heading>
                                    <S.Form>
                                        <InputHookForm
                                            errors={errors.name}
                                            name='name'
                                            control={control}
                                            label='Nome'
                                            type='text'
                                            placeholder='Digite o nome do evento'
                                        />
                                        <ErrorMessage errors={errors} name='name' render={ErrorsComponent} />
                                        <S.InputsInLine>
                                            <S.ViewInput>
                                                <InputHookForm
                                                    errors={errors.date}
                                                    name='date'
                                                    control={control}
                                                    label='Data'
                                                    type='date'
                                                    placeholder='__/__/__'
                                                />
                                                <ErrorMessage errors={errors} name='date' render={ErrorsComponent} />
                                            </S.ViewInput>
                                            <S.ViewInput>
                                                <InputHookForm
                                                    errors={errors.time}
                                                    name='time'
                                                    control={control}
                                                    label='Horário'
                                                    type='hours'
                                                    placeholder='Hora:min'
                                                />
                                                <ErrorMessage errors={errors} name='time' render={ErrorsComponent} />
                                            </S.ViewInput>
                                        </S.InputsInLine>
                                        <InputSelect
                                            list={categories}
                                            setProps={setIdCategory}
                                            initialValue={idCategory}
                                            label='Categoria'
                                            errors={errors.idCategory}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name='idCategory'
                                            render={ErrorsComponent}
                                        />

                                        <InputHookForm
                                            errors={errors.address}
                                            name='address'
                                            control={control}
                                            label='Endereço'
                                            type='text'
                                            placeholder='Digite o endereço do evento'
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name='address'
                                            render={ErrorsComponent}
                                        />

                                        <InputHookForm
                                            errors={errors.color}
                                            name='color'
                                            control={control}
                                            label='Cor do background'
                                            type='hex'
                                            placeholder='Hex'

                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name='color'
                                            render={ErrorsComponent}
                                        />
                                        <InputHookForm
                                            errors={errors.nameColor}
                                            name='nameColor'
                                            control={control}
                                            label='Cor do título'
                                            type='hex'
                                            placeholder='Hex'

                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name='nameColor'
                                            render={ErrorsComponent}
                                        />
                                        <InputHookForm
                                            errors={errors.messageWhatsapp}
                                            name='messageWhatsapp'
                                            control={control}
                                            label='Mensagem Whatsapp'
                                            type='descriptions'
                                            align='flex-start'
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name='messageWhatsapp'
                                            render={ErrorsComponent}
                                        />
                                        <InputHookForm
                                            errors={errors.description}
                                            name='description'
                                            control={control}
                                            label='Descrição'
                                            type='descriptions'
                                            align='flex-start'
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name='description'
                                            render={ErrorsComponent}
                                        />
                                        <InputHookForm
                                            errors={errors.zapiInstance}
                                            name='zapiInstance'
                                            control={control}
                                            label='Z-API Instância'
                                            type='text'
                                            align='flex-start'
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name='zapiInstance'
                                            render={ErrorsComponent}
                                        />
                                        <InputHookForm
                                            errors={errors.zapiToken}
                                            name='zapiToken'
                                            control={control}
                                            label='Z-API Token'
                                            type='text'
                                            align='flex-start'
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name='zapiToken'
                                            render={ErrorsComponent}
                                        />
                                        <S.VacinityContainer mt={16}>
                                            <Heading color='gray' size='small'>
                                                Comprovante de vacinação
                                            </Heading>
                                            <Switch
                                                trackColor={switchConfig.trackColor}
                                                thumbColor={thumbColorVaccination}
                                                ios_backgroundColor='#B2BEC3'
                                                onValueChange={toggleSwitch}
                                                value={isEnabled}
                                                style={switchConfig.styles}
                                            />
                                        </S.VacinityContainer>
                                        <S.VacinityContainer mt={5}>
                                            <Heading color='gray' size='small'>
                                                Foto de perfil
                                            </Heading>
                                            <Switch
                                                trackColor={switchConfig.trackColor}
                                                thumbColor={thumbColorPhoto}
                                                ios_backgroundColor='#B2BEC3'
                                                onValueChange={toggleIsPhoto}
                                                value={isPhoto}
                                                style={switchConfig.styles}
                                            />
                                        </S.VacinityContainer>
                                        <S.VacinityContainer mt={5}>
                                            <Heading color='gray' size='small'>
                                                Permitido acompanhante(s)
                                            </Heading>
                                            <Switch
                                                trackColor={switchConfig.trackColor}
                                                thumbColor={thumbColorGroup}
                                                ios_backgroundColor='#B2BEC3'
                                                onValueChange={toggleSwitchGroup}
                                                value={isGuestGroup}
                                                style={switchConfig.styles}
                                            />
                                        </S.VacinityContainer>
                                        <S.VacinityContainer mt={5}>
                                            <Heading color='gray' size='small'>
                                                Permitido email
                                            </Heading>
                                            <Switch
                                                trackColor={switchConfig.trackColor}
                                                thumbColor={thumbColorEmail}
                                                ios_backgroundColor='#B2BEC3'
                                                onValueChange={toggleSwitchEmail}
                                                value={isEmail}
                                                style={switchConfig.styles}
                                            />
                                        </S.VacinityContainer>
                                        <S.VacinityContainer mt={5}>
                                            <Heading color='gray' size='small'>
                                                Permitido RG
                                            </Heading>
                                            <Switch
                                                trackColor={switchConfig.trackColor}
                                                thumbColor={thumbColorRg}
                                                ios_backgroundColor='#B2BEC3'
                                                onValueChange={toggleSwitchRg}
                                                value={isRg}
                                                style={switchConfig.styles}
                                            />
                                        </S.VacinityContainer>
                                        <S.VacinityContainer mt={5}>
                                            <Heading color='gray' size='small'>
                                                Permitido CPF
                                            </Heading>
                                            <Switch
                                                trackColor={switchConfig.trackColor}
                                                thumbColor={thumbColorCpf}
                                                ios_backgroundColor='#B2BEC3'
                                                onValueChange={toggleSwitchCpf}
                                                value={isCpf}
                                                style={switchConfig.styles}
                                            />
                                        </S.VacinityContainer>
                                        <S.VacinityContainer mt={5}>
                                            <Heading color='gray' size='small'>
                                                Permitido endereço
                                            </Heading>
                                            <Switch
                                                trackColor={switchConfig.trackColor}
                                                thumbColor={thumbColorAddress}
                                                ios_backgroundColor='#B2BEC3'
                                                onValueChange={toggleSwitchAddress}
                                                value={isAddress}
                                                style={switchConfig.styles}
                                            />
                                        </S.VacinityContainer>
                                        <S.VacinityContainer mt={5}>
                                            <Heading color='gray' size='small'>
                                                Permitido número da sandália
                                            </Heading>
                                            <Switch
                                                trackColor={switchConfig.trackColor}
                                                thumbColor={thumbColorNumberShoe}
                                                ios_backgroundColor='#B2BEC3'
                                                onValueChange={toggleSwitchNumberShoe}
                                                value={isNumberShoe}
                                                style={switchConfig.styles}
                                            />
                                        </S.VacinityContainer>
                                        <S.VacinityContainer mt={5}>
                                            <Heading color='gray' size='small'>
                                                Permitido profissão
                                            </Heading>
                                            <Switch
                                                trackColor={switchConfig.trackColor}
                                                thumbColor={thumbColorProfession}
                                                ios_backgroundColor='#B2BEC3'
                                                onValueChange={toggleSwitchProfession}
                                                value={isProfession}
                                                style={switchConfig.styles}
                                            />
                                        </S.VacinityContainer>
                                        <S.VacinityContainer mt={5}>
                                            <Heading color='gray' size='small'>
                                                Permitido cargo
                                            </Heading>
                                            <Switch
                                                trackColor={switchConfig.trackColor}
                                                thumbColor={thumbColorOffice}
                                                ios_backgroundColor='#B2BEC3'
                                                onValueChange={toggleSwitchOffice}
                                                value={isOffice}
                                                style={switchConfig.styles}
                                            />
                                        </S.VacinityContainer>
                                        <S.VacinityContainer mt={5}>
                                            <Heading color='gray' size='small'>
                                                Permitido nome da empresa
                                            </Heading>
                                            <Switch
                                                trackColor={switchConfig.trackColor}
                                                thumbColor={thumbColorBusiness}
                                                ios_backgroundColor='#B2BEC3'
                                                onValueChange={toggleSwitchBusiness}
                                                value={isBusiness}
                                                style={switchConfig.styles}
                                            />
                                        </S.VacinityContainer>
                                        <S.VacinityContainer mt={5}>
                                            <Heading color='gray' size='small'>
                                                Dual link
                                            </Heading>
                                            <Switch
                                                trackColor={switchConfig.trackColor}
                                                thumbColor={thumbColorDualLink}
                                                ios_backgroundColor='#B2BEC3'
                                                onValueChange={toggleSwitchDualLink}
                                                value={dualLink}
                                                style={switchConfig.styles}
                                            />
                                        </S.VacinityContainer>
                                    </S.Form>
                                    <S.ContainerButton>
                                        <Button
                                            title={buttonTitle}
                                            onClick={handleSubmit(
                                                handleContinue,
                                                onError,
                                            )}
                                        />
                                    </S.ContainerButton>
                                </>
                            ) : (
                                <>
                                    <ScrollView>
                                        <Heading
                                            color='gray'
                                            size='intermediate'
                                        >
                                            Capa
                                        </Heading>
                                        <S.ButtonImageEvent
                                            height={151}
                                            onPress={pickImageFrontCover}
                                        >
                                            {frontCoverImage !== '' ? (
                                                <S.ImageButton
                                                    source={{
                                                        uri: frontCoverImage,
                                                    }}
                                                    resizeMode='cover'
                                                />
                                            ) : (
                                                <S.ImageButton
                                                    source={isWeb ? webCover : mobileCover}
                                                    resizeMode='cover'
                                                />
                                            )}
                                            {frontCoverImageLoading && <LoadingPhoto />}
                                            <S.IconCan name='camera' />
                                        </S.ButtonImageEvent>
                                        <S.ButtonLabel>
                                            Insira uma foto
                                        </S.ButtonLabel>
                                        <Heading color='gray' size='intermediate'>
                                            Convite
                                        </Heading>
                                        <S.ButtonImageEvent
                                            height={isWeb ? 400 : 450}
                                            width={250}
                                            onPress={pickImageInvitation}
                                        >
                                            {invitationImage !== '' ? (
                                                <S.ImageButton
                                                    source={{
                                                        uri: invitationImage,
                                                    }}
                                                    resizeMode='stretch'
                                                />
                                            ) : (
                                                <S.ImageButton
                                                    source={
                                                        isWeb
                                                            ? webInvite
                                                            : mobileInvitation
                                                    }
                                                    resizeMode='cover'
                                                />
                                            )}
                                            {invitationImageLoading && <LoadingPhoto />}
                                            <S.IconCan name='camera' />
                                        </S.ButtonImageEvent>
                                        <S.ButtonLabel>
                                            Insira uma foto
                                        </S.ButtonLabel>
                                        <InputHookForm
                                            errors={errors.widthInvitation}
                                            name='widthInvitation'
                                            control={control}
                                            label='Largura do convite'
                                            type='number'
                                            align='flex-start'
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name='widthInvitation'
                                            render={ErrorsComponent}
                                        />
                                        <InputHookForm
                                            errors={errors.heightInvitation}
                                            name='heightInvitation'
                                            control={control}
                                            label='Altura do convite'
                                            type='number'
                                            align='flex-start'
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name='heightInvitation'
                                            render={ErrorsComponent}
                                        />
                                        <InputHookForm
                                            errors={errors.widthQrcode}
                                            name='widthQrcode'
                                            control={control}
                                            label='Largura do QRCODE'
                                            type='number'
                                            align='flex-start'
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name='widthQrcode'
                                            render={ErrorsComponent}
                                        />
                                        <InputHookForm
                                            errors={errors.heightQrcode}
                                            name='heightQrcode'
                                            control={control}
                                            label='Altura do QRCODE'
                                            type='number'
                                            align='flex-start'
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name='heightQrcode'
                                            render={ErrorsComponent}
                                        />
                                        <InputHookForm
                                            errors={errors.positionYInvitation}
                                            name='positionYInvitation'
                                            control={control}
                                            label='Posição vertical do QRCODE'
                                            type='number'
                                            align='flex-start'
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name='positionYInvitation'
                                            render={ErrorsComponent}
                                        />
                                        <InputHookForm
                                            errors={errors.positionXInvitation}
                                            name='positionXInvitation'
                                            control={control}
                                            label='Posição horizontal do QRCODE'
                                            type='number'
                                            align='flex-start'
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name='positionXInvitation'
                                            render={ErrorsComponent}
                                        />
                                        <S.ContainerButton isFinal>
                                            <Button
                                                loading={loadingCreateEvent}
                                                disabled={buttonCreateDisabled}
                                                title={buttonTitle}
                                                onClick={handleSubmit(handleCreateEvent)}
                                            />
                                        </S.ContainerButton>
                                    </ScrollView>
                                </>
                            )}
                        </S.Content>
                    </S.Container>
                    <AwesomeAlert
                        show={openModalNotification}
                        title='Evento criado!'
                        message={`O evento ${eventName} foi criado com sucesso.`}
                        closeOnTouchOutside={false}
                        closeOnHardwareBackPress={false}
                        showConfirmButton
                        confirmText='OK'
                        confirmButtonColor='#98C828'
                        onConfirmPressed={handleNavigationHome}
                    />
                </S.Safe>
            )}
        </>
    );
}
