import React, { useState } from 'react';
import { DrawerItem } from '@react-navigation/drawer';
import { useDispatch } from 'react-redux';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import * as S from './styles';
import { defaultTheme as theme } from '../../styles/theme';

import QrIcon from '../../assets/images/svgs/qrCodeTabMenu';
import UserIcon from '../../assets/images/svgs/userTabMenu';
import CalendarsIcon from '../../assets/images/svgs/calendarTab';
import LogouIcon from '../../assets/images/svgs/logoutSvg';
import AdmIcon from '../../assets/images/svgs/usersSvg';
import { logoutAuth } from '../../store/modules/user/actions';
import { permissions } from '../../utils/permissions';

import { Can } from '../Can';

export function DrawerCustom({ ...props }) {
    const [currentDrawer, setCurrentDrawer] = useState('Eventos');
    const dispatch = useDispatch();
    const {
        permissionsAll,
        permissionRecepcionistAndMaster,
        permissionMaster,
    } = permissions;

    function handleLogout() {
        dispatch(logoutAuth());
        props.navigation.reset({
            index: 0,
            routes: [{ name: 'Login' }],
        });
        setCurrentDrawer('Eventos');
        props.navigation.closeDrawer();
    }

    function handleNavigateEventos() {
        props.navigation.reset({
            index: 0,
            routes: [{ name: 'Home' }],
        });
        setCurrentDrawer('Eventos');
    }

    function handleNavigateHosts() {
        props.navigation.reset({
            index: 0,
            routes: [{ name: 'Anfitriões' }],
        });
        setCurrentDrawer('Anfitriões');
    }

    function handleNavigateAdministrators() {
        const routes = [{ name: 'AdministratorsScreen' }];
        const reset = {
            index: 0,
            routes,
        };
        props.navigation.reset(reset);
        setCurrentDrawer('Administradores');
    }

    function handleNavigateQrCode() {
        props.navigation.reset({
            index: 0,
            routes: [{ name: 'Qr Code' }],
        });
        setCurrentDrawer('Qr Code');
    }

    function handleNavigateWhatsapp() {
        props.navigation.reset({
            index: 0,
            routes: [{ name: 'Whatsapp' }],
        });
        setCurrentDrawer('Whatsapp');
    }

    function renderCalendarsIcon(item: any) {
        const { color } = item;
        return (
            <CalendarsIcon color={color} />
        );
    }

    function renderUserIcon(item: any) {
        const { color } = item;
        return (
            <UserIcon color={color} />
        );

    }

    function renderAdmIcon(item: any) {
        const { color } = item;
        return (
            <AdmIcon color={color} />
        );
    }

    function renderQrIcon(item: any) {
        const { color } = item;
        return (
            <QrIcon color={color} />
        );
    }

    function renderWhatsappIcon(item: any) {
        const { color } = item;
        return (
            <MaterialCommunityIcons name='whatsapp' size={30} color={color} />
        );
    }

    function renderLogoutIcon(item: any) {
        const { color } = item;
        return (
            <LogouIcon color={color} />
        );
    }

    return (
        <S.Safe>
            <S.Container>
                <S.Navigation>
                    <S.Logo />
                    <Can role={permissionsAll}>
                        <DrawerItem
                            label='Eventos'
                            focused={currentDrawer === 'Eventos'}
                            activeTintColor={theme.colors.purpleDark}
                            inactiveTintColor={theme.colors.gray}
                            labelStyle={S.labelStyle}
                            style={S.DrawerItem}
                            onPress={handleNavigateEventos}
                            icon={renderCalendarsIcon}
                        />
                    </Can>
                    <Can role={permissionMaster}>
                        <DrawerItem
                            label='Anfitriões'
                            focused={currentDrawer === 'Anfitriões'}
                            activeTintColor={theme.colors.purpleDark}
                            inactiveTintColor={theme.colors.gray}
                            labelStyle={S.labelStyle}
                            style={S.DrawerItem}
                            onPress={handleNavigateHosts}
                            icon={renderUserIcon}
                        />
                        <DrawerItem
                            label='Administradores'
                            focused={currentDrawer === 'Administradores'}
                            activeTintColor={theme.colors.purpleDark}
                            inactiveTintColor={theme.colors.gray}
                            labelStyle={S.labelStyle}
                            style={S.DrawerItem}
                            onPress={handleNavigateAdministrators}
                            icon={renderAdmIcon}
                        />
                    </Can>
                    <Can role={permissionRecepcionistAndMaster}>
                        <DrawerItem
                            label='Qr Code'
                            focused={currentDrawer === 'Qr Code'}
                            activeTintColor={theme.colors.purpleDark}
                            inactiveTintColor={theme.colors.gray}
                            labelStyle={S.labelStyle}
                            style={S.DrawerItem}
                            onPress={handleNavigateQrCode}
                            icon={renderQrIcon}
                        />
                    </Can>
                    <Can role={permissionMaster}>
                        <DrawerItem
                            label='Whatsapp'
                            focused={currentDrawer === 'Whatsapp'}
                            activeTintColor={theme.colors.purpleDark}
                            inactiveTintColor={theme.colors.gray}
                            labelStyle={S.labelStyle}
                            style={S.DrawerItem}
                            onPress={handleNavigateWhatsapp}
                            icon={renderWhatsappIcon}
                        />
                    </Can>
                </S.Navigation>
                <DrawerItem
                    label='Sair'
                    inactiveTintColor={theme.colors.red}
                    labelStyle={{
                        fontFamily: `${theme.font.bold}`,
                    }}
                    style={S.DrawerItem}
                    onPress={handleLogout}
                    icon={renderLogoutIcon}
                />
            </S.Container>
        </S.Safe>
    );
}
