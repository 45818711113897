/* eslint-disable no-nested-ternary */
import styled from 'styled-components/native';
import { RFValue } from 'react-native-responsive-fontsize';
import { Feather } from '@expo/vector-icons';
import { FlatList, Platform, Dimensions, StatusBar } from 'react-native';
import Checkbox from 'expo-checkbox';
import { defaultTheme as theme } from '../../../styles/theme';
import { GuestsProps } from './components/CardGuest';

const windowWidth = Dimensions.get('window').width;

const isWeb = Platform.OS === 'web';

export const Safe = styled.SafeAreaView`
    flex: 1;
    ${StatusBar.currentHeight && `padding-top:${StatusBar.currentHeight}px`};
`;

export const Container = styled.View`
    padding: ${isWeb && windowWidth > 650 ? '4.875rem 8.265rem 1rem 8.265rem' : '40px 0px 10px 37px'} ;
    flex:1;
`;

export const Header = styled.View`
    flex-direction: row;
    /* align-items: center; */
    ${windowWidth < 650 && 'justify-content:space-between;'}
    align-items: flex-start;
`;

export const ButtonFilterContainer = styled.View`
    /* align-items: center;
    justify-content: center; */
    /* ${windowWidth > 650 && 'margin-left: 27px;'} */
    margin-right: ${isWeb && windowWidth > 650 ? '4.875rem' : `${RFValue(20)}px`};
    ${windowWidth > 650 && 'margin-top: -3px'};
    ${windowWidth > 650 && 'margin-left: 40px'};
`;
export const ButtonFilterIcon = styled(Feather)`
    font-size: 26px;
    color: ${theme.colors.grayLight};
`;

export const HostList = styled(FlatList as new () => FlatList<GuestsProps>).attrs(
    Platform.OS === 'web' && windowWidth > 650 ? {
        numColumns: 2,
        contentContainerStyle: {
            paddingRight: RFValue(37),
        },
        columnWrapperStyle: {
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    } : {
        numColumns: 1,
        contentContainerStyle: {
            paddingLeft: RFValue(-37),
            paddingRight: RFValue(37),
        },
    },
)``;

export const EmptyText = styled.Text`
    font-size: 16px;
    color: ${theme.colors.gray};
    font-family: ${theme.font.regular};
`;

export const Title = styled.Text`
    font-size: ${RFValue(20)}px;
    font-family: ${theme.font.bold};
    color: ${theme.colors.purpleDark};
    margin-bottom:${RFValue(26)}px;
    margin-top: ${Platform.OS === 'ios' ? `${RFValue(10)}px` : Platform.OS === 'android' ? `${RFValue(20)}px` : `${RFValue(5)}px`};
`;

export const InputSearch = styled.TextInput`
    font-size: 12px;
    font-family: ${theme.font.regular};
    height: 40px;
    padding: 10px;
    background-color: ${theme.colors.white};
    border-radius: 5px;
    width: 380px;
    margin-right: 12px;
`;

export const CheckboxButton = styled(Checkbox).attrs({
    color: theme.colors.purpleDark,
})``;

export const ContainerGuest = styled.View`
    /* background-color: orange; */
    flex: 1;
    flex-direction: row;
    align-items: center;
`;

export const InputContainer = styled.View`
    /* background-color: orange; */
    flex-direction: row;
    align-items: center;
    margin: 0px 37px 20px 0px;
`;
