import React, { useEffect, useState } from 'react';
import { RouteProp, useIsFocused } from '@react-navigation/native';

import { Chart } from './components/Chart';
import { Header } from '../../../components/Header';
import { Loading } from '../../../components/Loading';
import { dashboardEvent } from '../../../services/events';
import { graphicsGeneration, calculePercentage, DataProps, PieProps } from './utils';
import * as S from './styles';

export type ChartsEventScreen = {
    route: RouteProp<any, any>;
    navigation: any;
};

interface GuestsProps {
    name: string;
}

interface InfoProps {
    qtd: number;
    guests: Array<GuestsProps>;
}

interface AllInfoProps {
    on: InfoProps;
    refused: InfoProps;
    off: InfoProps;
}

export function ChartsEventScreen({
    route: { params },
    navigation,
}: ChartsEventScreen) {
    const isFocused = useIsFocused();
    const [loading, setLoading] = useState(true);
    const [data, setDate] = useState<DataProps>({} as DataProps);
    const [pieProps, setPieProps] = useState<PieProps[]>([]);
    const [pieScan, setPieScan] = useState<PieProps[]>([]);
    const [allInfo, setAllInfo] = useState<AllInfoProps>({} as AllInfoProps);

    const getDashboard = async () => {
        setLoading(true);
        const { result } = await dashboardEvent(params?.idEvent);
        const data = {
            guests: {
                defaulters: calculePercentage(result.guests.all, result.guests.off.qtd),
                gift: calculePercentage(result.guests.all, result.guests.on.qtd),
                total: result.guests.all,
                defaultersTotal: result.guests.off.qtd,
                giftTotal: result.guests.on.qtd,
            },
            scans: {
                correct: calculePercentage(result.scans.total, result.scans.correct.qtd),
                incorrect: calculePercentage(result.scans.total, result.scans.incorrect.qtd),
                refused: calculePercentage(result.scans.total, result.scans.refused?.qtd),
                total: result.scans.total,
                refusedTotal: result.scans.refused?.qtd,
                incorrectTotal: result.scans.incorrect.qtd,
                correctTotal: result.scans.correct.qtd,
            },
        };

        const pieChartInfo = graphicsGeneration(data, 'guests');
        const pieChartInfoScan = graphicsGeneration(data, 'scans');

        setPieProps(pieChartInfo);
        setPieScan(pieChartInfoScan);
        setDate(data);
        setAllInfo(result.guests);
        setLoading(false);
    };

    const goBack = () => {
        navigation.navigate('EventDetailsScreen', { event: params?.event });
    };

    useEffect(() => {
        getDashboard();
    }, [isFocused]);

    useEffect(() => {
        navigation.setOptions({
            title: `Estatísticas | ${params?.event.name}`,
        });
    }, [navigation, params]);

    if (loading) {
        return (
            <Loading />
        );
    }

    return (
        <S.Safe>
            <S.Container>
                <S.Header>
                    <Header
                        title='Gráficos'
                        navigation={navigation}
                        marginBottom={0}
                        handleGoback={goBack}
                    />
                </S.Header>
                <S.Charts>
                    <Chart
                        data={data}
                        title='Convidados'
                        allInfo={allInfo}
                        chart={pieProps}
                        type='guests'
                    />
                    <Chart
                        data={data}
                        title='Escaneamentos'
                        allInfo={allInfo}
                        chart={pieScan}
                        type='scans'
                    />
                </S.Charts>
            </S.Container>
        </S.Safe>
    );
}
