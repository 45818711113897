import React, { useEffect, useState } from 'react';
import { Image } from 'react-native';
import { RouteProp, useIsFocused } from '@react-navigation/native';
import AwesomeAlert from 'react-native-awesome-alerts';
import { MenuProvider } from 'react-native-popup-menu';
import { guestDelete, guestUpdateStatus } from '../../../services/guests';

import * as S from './styles';
import { Header } from '../../../components/Header';
import { CardGuest } from './components/CardGuest';
import { Loading } from '../../../components/Loading';
import { hostsListByEvent } from '../../../services/hosts';
import { ListEmpty } from '../../../components/ListEmpty';
import { Dropdown } from '../../../components/Dropdown';

import { checkWhatsapp } from '../../../services/client';
import { defaultTheme as theme } from '../../../styles/theme';

export type GuestsScreenProps = {
    route: RouteProp<any, any>;
    navigation: any;
};

interface WhatsappStatusProps {
    connected: boolean;
    qrcode: string;
}

export function GuestsScreen({
    route: { params },
    navigation,
}: GuestsScreenProps) {
    const [guests, setGuests] = useState<any>([]);
    const [, setRefreshing] = useState(false);
    const [loading, setLoading] = useState(true);
    const [searchTxt, setSearchTxt] = useState('');
    const [modalDelete, setModalDelete] = useState(false);
    const [guestId, setGuestId] = useState('');
    const [filter, setFilter] = useState('');
    const [whatsappStatus, setWhatsappStatus] = useState(
        {} as WhatsappStatusProps,
    );
    const [selectGuests, setSelectGuests] = useState<number[]>([]);
    const [changedStatus, setChangedStatus] = useState<'pending' | 'approved' | 'disapproved' | null>(null);

    const isFocused = useIsFocused();

    useEffect(() => {
        changedStatusGuests();
    }, [changedStatus]);

    const changedStatusGuests = async () => {
        if (changedStatus) {
            setLoading(true);
            await guestUpdateStatus(changedStatus, selectGuests).then((response) => {
                if (response.status === 200) {
                    getGuests();
                    setLoading(false);
                    setSelectGuests([]);
                    setChangedStatus(null);
                }
            });
        }
    };

    const handleDeleteGuest = async () => {
        setLoading(true);
        const id = Number(guestId);
        await guestDelete(id).then((response) => {
            if (response.status === 200) {
                setLoading(false);
                getGuests();
                setModalDelete(!modalDelete);
            }
        });
    };

    const handleAllGuests = () => {
        setSelectGuests(selectGuests.length === guests.length ? [] : guests.map((g) => g.id));
    };

    const cardGuests = (guest) => {
        const openModalDelete = async () => {
            setGuestId(guest.item.id);
            setModalDelete(!modalDelete);
        };

        const value = selectGuests.includes(guest.item.id);
        const onValueChange = () => {
            if (value) {
                setSelectGuests(selectGuests.filter((e: any) => e !== guest.item.id));
            } else {
                setSelectGuests([...selectGuests, guest.item.id]);
            }
        };

        return (
            <S.ContainerGuest>
                <S.CheckboxButton value={value} onValueChange={onValueChange} />
                <CardGuest
                    connected={whatsappStatus?.connected}
                    getGuests={getGuests}
                    guest={guest.item}
                    navigation={navigation}
                    event={params?.event}
                    openModalDelete={openModalDelete}
                />
            </S.ContainerGuest>
        );
    };

    const getGuests = async () => {
        setLoading(true);
        setRefreshing(true);
        const data = await hostsListByEvent(params?.idEvent, searchTxt, filter);
        setGuests(data.result.list);
        setRefreshing(false);
        setLoading(false);
        return data;
    };

    async function getStatusWhatsapp() {
        try {
            const response = await checkWhatsapp();
            setWhatsappStatus(response.result);
        } catch (e) {
            return null;
        }
        return null;
    }

    const goBack = () => {
        navigation.navigate('EventDetailsScreen', { event: params?.event });
    };

    useEffect(() => {
        getStatusWhatsapp();
        setGuests([]);
        getGuests();
    }, [isFocused]);

    const optionsDropdown = [
        {
            title: 'Todos',
            nameIcon: 'users',
            onSelect: () => {
                setFilter('');
            },
            typeIcon: 'Feather',
        },
        {
            title: 'Em espera',
            nameIcon: 'refresh-ccw',
            onSelect: () => {
                setFilter('pending');
            },
            typeIcon: 'Feather',
        },
        {
            title: 'Aprovados',
            nameIcon: 'checkbox-marked-circle-outline',
            onSelect: () => {
                setFilter('approved');
            },
        },
        {
            title: 'Reprovados',
            nameIcon: 'block-helper',
            onSelect: () => {
                setFilter('disapproved');
            },
        },
        {
            title: 'Scaneados',
            nameIcon: 'qrcode-scan',
            onSelect: () => {
                setFilter('checked');
            },
        },
    ];

    const optionsStatus = [
        {
            type: 'pending',
            title: 'Em espera',
            nameIcon: 'refresh-ccw',
            onSelect: () => {
                setChangedStatus('pending');
            },
            typeIcon: 'Feather',
        },
        {
            type: 'approved',
            title: 'Aprovados',
            nameIcon: 'checkbox-marked-circle-outline',
            onSelect: () => {
                setChangedStatus('approved');
            },
        },
        {
            type: 'disapproved',
            title: 'Reprovados',
            nameIcon: 'block-helper',
            onSelect: () => {
                setChangedStatus('disapproved');
            },
        },
    ];

    function renderLoading() {
        if (loading) {
            return (
                <Loading />
            );
        }
        if (!loading && guests.length === 0) {
            return (
                <ListEmpty message='Nenhum convidado cadastrado' />
            );
        }
        return null;
    }

    function renderList() {
        if (loading) {
            return null;
        }

        return (
            <S.HostList data={guests} renderItem={cardGuests} />
        );
    }

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            getGuests();
        }, 1000);
        return () => clearTimeout(timeOutId);
    }, [searchTxt, filter]);

    const nameChangedStatus = () => {
        if (changedStatus === 'approved') {
            return 'Aprovado';
        } if (changedStatus === 'disapproved') {
            return 'Reprovado';
        } if (changedStatus === 'pending') {
            return 'Pendente';
        }
        return '';
    };

    return (
        <MenuProvider>
            <S.Safe>
                {renderLoading()}
                <S.Container>
                    <S.Header>
                        <Header
                            title='Convidados'
                            navigation={navigation}
                            marginBottom={26}
                            handleGoback={goBack}
                        />
                        <S.ButtonFilterContainer>
                            <Dropdown
                                options={optionsDropdown}
                                icon={<S.ButtonFilterIcon name='filter' />}
                            />
                        </S.ButtonFilterContainer>
                    </S.Header>
                    <S.InputContainer>
                        <S.InputSearch placeholder='Pesquisar' onChangeText={setSearchTxt} value={searchTxt} />
                        <S.EmptyText style={{ marginRight: 12 }} onPress={handleAllGuests}>{`${selectGuests.length === guests.length ? 'Desmarcar' : 'Selecionar'} todos`}</S.EmptyText>
                        {selectGuests.length ? (
                            <>
                                <Dropdown
                                    options={optionsStatus}
                                    icon={<Image style={{ width: 16, height: 16 }} source={require('../../../assets/images/svgs/evento.png')} />}
                                />
                                <S.EmptyText style={{ marginLeft: 6 }}>{nameChangedStatus()}</S.EmptyText>
                            </>
                        ) : null}
                    </S.InputContainer>
                    {renderList()}
                </S.Container>
            </S.Safe>
            <AwesomeAlert
                show={modalDelete}
                title='Excluir Convidado?'
                message='O convidado será excluído do evento'
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showConfirmButton
                confirmText='Excluir'
                cancelText='Cancelar'
                cancelButtonColor={theme.colors.grayLight}
                confirmButtonColor={theme.colors.red}
                onConfirmPressed={handleDeleteGuest}
            />
        </MenuProvider>
    );
}
